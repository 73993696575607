import { createSlice } from '@reduxjs/toolkit';

export const toolsSlice = createSlice({
    name: 'tools',
    initialState: { tools: [] },
    reducers: {
        setTools: (state, action) => {
            state = action.payload;
        },
    },
});

export const { setTools } = toolsSlice.actions;

export default toolsSlice.reducer;