import React from 'react';
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import { Provider } from 'react-redux';
import store from './redux/store'
import './i18n';
import 'primeicons/primeicons.css';
import { PrimeReactProvider } from 'primereact/api';
import './styles/styles.scss';
import './index.css';
import './flags.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';  
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';




const children = (
	<AuthContextProvider>
		<ThemeContextProvider>
			<Router>
				<React.StrictMode>
					<PrimeReactProvider>
						<Provider store={store}>
							<App />
						</Provider>
					</PrimeReactProvider>
				</React.StrictMode>
			</Router>
		</ThemeContextProvider>
	</AuthContextProvider>
);



const container = document.getElementById('root');
createRoot(container as Element).render(children);

