import React, { ReactNode, useContext, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import {
	componentPagesMenu,
	dashboardPagesMenu,
	gettingStartedPagesMenu,
} from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Hand from '../../../assets/img/hand.png';
import HandWebp from '../../../assets/img/hand.webp';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const [doc, setDoc] = useState(
		localStorage.getItem('facit_asideDocStatus') === 'true' || false,
	);

	const { t } = useTranslation(['translation', 'menu']);

	const { darkModeStatus } = useDarkMode();

	const filterMenus = (menu: Record<string, any>) => {
		// Filter out the top-level menus with flag set to false
		const filteredMenu: Record<string, any> = Object.keys(menu).reduce((acc, key) => {
			const item = menu[key];
	
			// Only include items with flag !== false
			if (item.flag !== false) {
				// If the menu item has a subMenu, recursively filter its items
				if (item.subMenu) {
					item.subMenu = filterMenus(item.subMenu);
				}
				acc[key] = item;
			}
			return acc;
		}, {} as Record<string, any>);
	
		return filteredMenu;
	};
	

	// Filter the menus before passing them to the Navigation component
	const filteredDashboardMenu = filterMenus(dashboardPagesMenu);
	const filteredComponentMenu = filterMenus(componentPagesMenu);
	const filteredGettingStartedMenu = filterMenus(gettingStartedPagesMenu);

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				{!doc && (
					<>
						<Navigation menu={filteredDashboardMenu} id='aside-dashboard' />
					</>
				)}

				{doc && (
					<>
						<Navigation menu={filteredGettingStartedMenu} id='aside-docMenu' />
						<NavigationLine />
						<Navigation menu={filteredComponentMenu} id='aside-componentsMenu' />
						<NavigationLine />
					</>
				)}

				{asideStatus && doc && (
					<Card className='m-3 '>
						<CardBody className='pt-0'>
							<img srcSet={HandWebp} src={Hand} alt='Hand' width={130} height={130} />
							<p
								className={classNames('h4', {
									'text-dark': !darkModeStatus,
									'text-light': darkModeStatus,
								})}>
								{t('Everything is ready!') as ReactNode}
							</p>
							<Button
								color='info'
								isLight
								className='w-100'
								onClick={() => {
									localStorage.setItem('facit_asideDocStatus', 'false');
									setDoc(false);
								}}>
								{t('Demo Pages') as ReactNode}
							</Button>
						</CardBody>
					</Card>
				)}
			</AsideBody>
			<AsideFoot>
				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
