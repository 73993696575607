import React from 'react';
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import Footer from '../../../layout/Footer/Footer';
import {footerStyles,footerCopanyStyles} from '../../../commonstyle/styles';
import {constValue} from '../../../commonstyle/constName';
import { Typography, Box } from '@mui/material';

const DefaultFooter = () => {
	const { darkModeStatus } = useDarkMode();

	return (
		<Footer>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col'>
						<Typography style={footerCopanyStyles}>{constValue.footerCompanyName}</Typography>
					</div>
					<div className='col-auto'>
						<a
							href='/'
							className={classNames('text-decoration-none', {
								'link-dark': !darkModeStatus,
								'link-light': darkModeStatus,
							})}>
							<Typography style={footerStyles}>{constValue.footerProjectName}</Typography>
						</a>
					</div>
				</div>
			</div>
		</Footer>
	);
};

export default DefaultFooter;
