import { devServer } from '../http-common';

const login = async (data: any) => {
	try {
		const response = await devServer.post(`/token/`, data, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};


const abacAccess = async (data: any) => {
	try {
		const response = await devServer.get(`/policies/`+data,);
		return response.data;
	} catch (error) {
		throw error;
	}
};
const forgotPassword =async (data: any) => {
	try {
		const response = await devServer.post('/forgot-password', data);
		return response?.data;
	} catch (error) {
		throw error;
	}
};



const createPassword =async (data: any) => {
	try {
		const response = await devServer.post('/create-password', data);
		return response?.data;
	} catch (error) {
		throw error;
	}
};


const updatePassword =async (data: any) => {
	try {
		const response = await devServer.post('/update-password', data);
		return response?.data;
	} catch (error) {
		throw error;
	}
};

const resendCode= async(data: any) => {
	try {
		const response = await devServer.post('/resend-code', data);
		return response?.data;
	} catch (error) {
		throw error;
	}
};

const verificationCode= async(data: any) => {
	try {
		const response = await devServer.post('/verify-code', data);
		return response?.data;
	} catch (error) {
		throw error;
	}
};


const resetPassword=async (data: any) => {
	try {
		const response = await devServer.post('/reset-password', data);
		return response?.data;
	} catch (error) {
		throw error;
	}
};


const LoginServices = {
	login,
	forgotPassword,
	createPassword,
	updatePassword,
	resendCode,
	verificationCode,
	resetPassword,
	abacAccess
};

export default LoginServices;
