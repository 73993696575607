import React, { SVGProps } from 'react';

const SvgExtensionOff = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" width="36" height="36" rx="10" fill="#E7EEF8" />
		<g clip-path="url(#clip0_1877_17687)">
			<path opacity="0.3" d="M18.4984 13C16.9662 12.995 15.4635 13.4224 14.1633 14.2332C12.8631 15.0439 11.8181 16.2052 11.1484 17.5834C12.5234 20.3917 15.3401 22.1667 18.4984 22.1667C21.6568 22.1667 24.4734 20.3917 25.8484 17.5834C25.1788 16.2052 24.1337 15.0439 22.8335 14.2332C21.5333 13.4224 20.0307 12.995 18.4984 13ZM18.4984 21.3334C16.4318 21.3334 14.7484 19.65 14.7484 17.5834C14.7484 15.5167 16.4318 13.8334 18.4984 13.8334C20.5651 13.8334 22.2484 15.5167 22.2484 17.5834C22.2484 19.65 20.5651 21.3334 18.4984 21.3334Z" fill="#7F8388" />
			<path d="M18.5026 11.3335C14.3359 11.3335 10.7776 13.9252 9.33594 17.5835C10.7776 21.2418 14.3359 23.8335 18.5026 23.8335C22.6693 23.8335 26.2276 21.2418 27.6693 17.5835C26.2276 13.9252 22.6693 11.3335 18.5026 11.3335ZM18.5026 22.1668C16.9703 22.1719 15.4677 21.7445 14.1675 20.9337C12.8673 20.1229 11.8223 18.9617 11.1526 17.5835C12.5276 14.7752 15.3443 13.0002 18.5026 13.0002C21.6609 13.0002 24.4776 14.7752 25.8526 17.5835C25.1829 18.9617 24.1379 20.1229 22.8377 20.9337C21.5375 21.7445 20.0349 22.1719 18.5026 22.1668ZM18.5026 13.8335C16.4359 13.8335 14.7526 15.5168 14.7526 17.5835C14.7526 19.6502 16.4359 21.3335 18.5026 21.3335C20.5693 21.3335 22.2526 19.6502 22.2526 17.5835C22.2526 15.5168 20.5693 13.8335 18.5026 13.8335ZM18.5026 19.6668C17.9501 19.6668 17.4202 19.4473 17.0295 19.0566C16.6388 18.6659 16.4193 18.136 16.4193 17.5835C16.4193 17.031 16.6388 16.5011 17.0295 16.1104C17.4202 15.7197 17.9501 15.5002 18.5026 15.5002C19.0551 15.5002 19.585 15.7197 19.9757 16.1104C20.3664 16.5011 20.5859 17.031 20.5859 17.5835C20.5859 18.136 20.3664 18.6659 19.9757 19.0566C19.585 19.4473 19.0551 19.6668 18.5026 19.6668Z" fill="#7F8388" />
		</g>
		<defs>
			<clipPath id="clip0_1877_17687">
				<rect width="20" height="20" fill="white" transform="translate(8.5 8)" />
			</clipPath>
		</defs>
	</svg>
	);
};

export default SvgExtensionOff;
