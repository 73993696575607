// actions.ts

// Action types
export const FETCH_POLICY_FOR_EMAIL_REQUEST = 'FETCH_POLICY_FOR_EMAIL_REQUEST ';
export const FETCH_POLICY_FOR_EMAIL_SUCCESS = 'FETCH_POLICY_FOR_EMAIL_SUCCESS';
export const FETCH_POLICY_FOR_EMAIL_FAILURE = 'FETCH_POLICY_FOR_EMAIL_FAILURE';

// Action creators
export const fetchPolicyForEmailRequest = () => ({
  type: FETCH_POLICY_FOR_EMAIL_REQUEST,
});

export const fetchPolicyForEmailSuccess = (data: any[]) => ({
  type: FETCH_POLICY_FOR_EMAIL_SUCCESS,
  payload: data,
});

export const fetchPolicyForEmailFailure = (error: string) => ({
  type: FETCH_POLICY_FOR_EMAIL_FAILURE,
  payload: error,
});

