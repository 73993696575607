import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface QualifyLogoProps {
  width?: number;
  height?: number;
}
const Logo: FC<QualifyLogoProps> = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clip-path="url(#clip0_2758_29135)">
        <path opacity="0.3" d="M13 4H6V20H18V9H13V4ZM16 18H8V16H16V18ZM16 12V14H8V12H16Z" fill="#46BCAA" />
        <path d="M8 16H16V18H8V16ZM8 12H16V14H8V12ZM14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z" fill="#46BCAA" />
      </g>
      <defs>
        <clipPath id="clip0_2758_29135">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
Logo.defaultProps = {
  width: 2155,
  height: 854,
};

export default Logo;