import React from 'react';
import { RouteProps } from 'react-router-dom';
import DefaultFooter from '../pages/_layout/_footers/DefaultFooter';

const footers: RouteProps[] = [
	{ path: '/', element: null },
	{ path: '/', element: null },
	{ path: '/', element: null },
	{ path: '/', element: null },
	{ path: '/forgotPassword', element: null },
	{ path: '/verificationCode', element: null },
	{ path: '/resetPassword', element: null },
	{ path: '*', element: <DefaultFooter /> },
];

export default footers;
