import { Box, styled, Typography } from '@mui/material';
import React, { useState,useEffect } from 'react';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Logo from '../../assets/loginPageAssets/TenderAILogo.svg';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import { LoginHeader, sliderBodyText, sliderHearedText } from '../../commonstyle/styles';
import Input from '../../components/bootstrap/forms/Input';
import AuthServices from '../../services/AuthServices';
import ToastComponent from '../components/toast/toast';
import { useNavigate } from 'react-router-dom';

const SubmitButton = styled('button')({
	width: '32rem',
	height: '3rem',
	background: 'linear-gradient(135deg, rgb(233, 53, 221), rgb(88, 135, 245))',
	color: 'white',
	border: '0px',
	marginTop: '1rem',
	borderRadius: '13px',
	cursor: 'pointer',
});

function UpdatePassword({ setUpdatePassword, setLoginIsVisible }: any) {
	const [current_password, setCurrent_Password] = useState<string>('');
	const [new_password, setNew_Password] = useState<string>('');
	const [confirm_password, setConfirm_Password] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [showToast, setShowToast] = useState(false);
	const [isDisable, setDisable] = useState(true);
	const [toastSeverity, setToastSeverity] = useState<'success' | 'error' | undefined>(undefined);
	const [toastMessage, setToastMessage] = useState<string>('');
	const [MatchingPasswordRequired, setMatchingPasswordRequired] = useState<boolean>(false);
	const [CurrentPasswordRequired, setCurrentPasswordRequired] = useState<boolean>(false);
	const isLengthValid = new_password.length >= 8 && new_password.length <= 12;
	const hasUpperCase = /[A-Z]/.test(new_password);
	const hasLowerCase = /[a-z]/.test(new_password);
	const hasNumber = /\d/.test(new_password);
	const containsSpecialChar = /[^a-zA-Z0-9]/.test(new_password);


	const navigate = useNavigate();

	const ValidationItem = ({ isValid, text }: { isValid: boolean; text: string }) => (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: '8px',
				color: isValid ? 'green' : 'red',
				marginBottom: '2px',
			}}>
			{isValid ? <i className='pi pi-check'></i> : <i className='pi pi-times'></i>}
			<Typography>{text}</Typography>
		</Box>
	);

	const handleChangeCurrentPassword = (e: any) => {
		setCurrent_Password(e.target.value);
		setCurrentPasswordRequired(false);
	};
	const handleChangeConfirmPassword = (e: any) => {
		setConfirm_Password(e.target.value);
	};
	const handleChangNewPassword = (e: any) => {
		setNew_Password(e.target.value);
		setMatchingPasswordRequired(false);
	};

	const handleUpdate = async () => {
		setShowToast(false);
		if (new_password !== confirm_password) {
			setMatchingPasswordRequired(true);
			alert('Password and Confirm Passwords dont match');
		}else{
			try {
				setShowToast(false);
				const payload = {
					email: localStorage.getItem('email'),
					current_password: current_password,
					new_password: new_password,
					confirm_new_password: confirm_password,
				};
				const response = await AuthServices.updatePassword(payload);
				if (response?.error) {
					setShowToast(true);
					setToastSeverity('error');
					setToastMessage(response?.message);
				} else {
					setShowToast(true);
					setToastSeverity('success');
					setToastMessage(response?.message);
					setTimeout(() => {
						navigate('/');
					}, 5000)
					
				}
			} catch (error) {
				setCurrentPasswordRequired(true);
				setShowToast(true);
				setToastSeverity('error');
				setToastMessage('Current Password provided is incorrect');
			} finally {
				setLoading(false);
			}
		}
		
	};

	useEffect(() => {
		if (
		  current_password?.length >= 8 &&
		  new_password?.length >= 8 &&
		  confirm_password?.length >= 8
		) {
		  setDisable(false);
		} else {
		  setDisable(true);
		}
	  }, [current_password, confirm_password, new_password]);
	  

	return (
		<Box
			sx={{
				maxWidth: 'fit-content',
				marginLeft: 'auto',
				marginRight: 'auto',
				paddingTop: '10rem',
			}}>
			{showToast && (
				<ToastComponent
					severity={toastSeverity}
					label={toastSeverity === 'error' ? 'Error' : 'Success'}
					message={toastMessage}
					show={showToast}
				/>
			)}
			<Card style={{ width: '35rem' }}>
				<CardBody>
					<Box sx={{ maxWidth: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}>
						<img src={Logo} alt='' />
					</Box>
					<Box sx={{ marginTop: '3rem' }}>
						<Typography style={LoginHeader}>Update Password</Typography>
					</Box>
					<div className='row g-3 my-2'>
						<Box sx={{ height: 10 }}>
							<Typography style={sliderHearedText}>Current Password</Typography>
						</Box>
						<div className='col-12'>
							<FormGroup id='current_password' label=''>
								<Input
									type='password'
									name='current_password'
									placeholder=''
									onChange={handleChangeCurrentPassword}
									value={current_password}
									style={{
										...sliderBodyText,
										border: CurrentPasswordRequired
											? '1px solid red'
											: '1px solid transparent',
									}}
									
								/>
							</FormGroup>
						</div>
						<Box sx={{ height: 10 }}>
							<Typography style={sliderHearedText}>New Password</Typography>
						</Box>
						<div className='col-12'>
							<FormGroup id='new_password' label=''>
								<Input
									type='password'
									name='new_password'
									placeholder=''
									onChange={handleChangNewPassword}
									value={new_password}
									style={{
										...sliderBodyText,
									}}
									
								/>
							</FormGroup>
						</div>
						<Box sx={{ height: 10 }}>
							<Typography style={sliderHearedText}>Confirm Password</Typography>
						</Box>
						<div className='col-12'>
							<FormGroup id='confirm_password' label=''>
								<Input
									type='password'
									name='confirm_password'
									placeholder=''
									onChange={handleChangeConfirmPassword}
									value={confirm_password}
									style={{
										...sliderBodyText,
										border: MatchingPasswordRequired
											? '1px solid red'
											: '1px solid transparent',
									}}
								/>
							</FormGroup>
						</div>
						<Box>
							<SubmitButton disabled={isDisable}  onClick={handleUpdate}>Update</SubmitButton>
						</Box>
					</div>
					<Box sx={{ marginTop: '2rem', marginLeft: '2rem' }}>
						<ValidationItem
							isValid={isLengthValid}
							text='Password must be 8-12 characters long'
						/>
						<ValidationItem
							isValid={hasUpperCase}
							text='Password should contain atleast one upper case letter'
						/>
						<ValidationItem
							isValid={hasLowerCase}
							text='Password should contain atleast one lower case letter'
						/>
						<ValidationItem
							isValid={hasNumber}
							text='Password must have at least one number'
						/>
						<ValidationItem
							isValid={containsSpecialChar}
							text='Password must have at least one Special characters '
						/>
					</Box>
				</CardBody>
			</Card>
		</Box>
	);
}

export default UpdatePassword;
