import React, { SVGProps } from 'react';

const SvgCustomCompany = (props: SVGProps<SVGSVGElement>) => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clip-path="url(#clip0_6014_11973)">
<path d="M17 11V3H7V7H3V21H11V17H13V21H21V11H17ZM7 19H5V17H7V19ZM7 15H5V13H7V15ZM7 11H5V9H7V11ZM11 15H9V13H11V15ZM11 11H9V9H11V11ZM11 7H9V5H11V7ZM15 15H13V13H15V15ZM15 11H13V9H15V11ZM15 7H13V5H15V7ZM19 19H17V17H19V19ZM19 15H17V13H19V15Z" fill="#8E98A7"/>
</g>
<defs>
<clipPath id="clip0_6014_11973">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>



);

export default SvgCustomCompany;
