import React, { SVGProps } from 'react';

const SvgCustomCreateSchema = (props: SVGProps<SVGSVGElement>) => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clip-path="url(#clip0_6014_11993)">
<path opacity="0.3" d="M15 5H5V19H19V9H15V5ZM7 7H12V9H7V7ZM17 17H7V15H17V17ZM17 11V13H7V11H17Z" fill="#8E98A7"/>
<path d="M7 13H17V11H7V13ZM7 17H17V15H7V17ZM16 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V8L16 3ZM19 19H5V5H15V9H19V19ZM12 7H7V9H12V7Z" fill="#8E98A7"/>
</g>
<defs>
<clipPath id="clip0_6014_11993">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>




);

export default SvgCustomCreateSchema;
