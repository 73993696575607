import React, { SVGProps } from 'react';

const SvgRedo = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<path d="M8 16C5.76667 16 3.875 15.225 2.325 13.675C0.775 12.125 0 10.2333 0 8C0 5.76667 0.775 3.875 2.325 2.325C3.875 0.775 5.76667 0 8 0C9.15 0 10.25 0.237333 11.3 0.712C11.7308 0.906759 12.1364 1.13597 12.5167 1.39964C13.0816 1.79127 14 1.42574 14 0.738376C14 0.330582 14.3306 0 14.7384 0H15C15.5523 0 16 0.447715 16 1V3.5C16 5.433 14.433 7 12.5 7H10C9.44771 7 9 6.55228 9 6C9 5.44772 9.44771 5 10 5H10.5423C11.7265 5 12.3969 3.72701 11.4206 3.05673C11.2892 2.96654 11.1534 2.88097 11.013 2.8C10.0883 2.26667 9.084 2 8 2C6.33333 2 4.91667 2.58333 3.75 3.75C2.58333 4.91667 2 6.33333 2 8C2 9.66667 2.58333 11.0833 3.75 12.25C4.91667 13.4167 6.33333 14 8 14C9.28333 14 10.4417 13.6333 11.475 12.9C12.3034 12.3121 12.9337 11.5742 13.3657 10.6864C13.5605 10.2863 13.9483 10 14.3933 10C15.0788 10 15.5808 10.6528 15.3026 11.2794C14.7713 12.4758 13.9704 13.4911 12.9 14.325C11.4667 15.4417 9.83333 16 8 16Z" fill="#5B6674" />
		</svg>
	);
};

export default SvgRedo;
