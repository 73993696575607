import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface QualifyLogoProps {
	width?: number;
	height?: number;
}
const Logo: FC<QualifyLogoProps> = ({ width, height }) => {
	return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
        <path d="M2.02632 16H3.47007L13.3737 6.225L11.9299 4.8L2.02632 14.575V16ZM0 18V13.75L13.3737 0.575C13.5763 0.391667 13.8002 0.25 14.0454 0.15C14.2906 0.0500001 14.5479 0 14.8174 0C15.0876 0 15.3493 0.0500001 15.6026 0.15C15.8559 0.25 16.0754 0.4 16.2612 0.6L17.6543 2C17.8569 2.18333 18.0048 2.4 18.098 2.65C18.1913 2.9 18.2375 3.15 18.2368 3.4C18.2368 3.66667 18.1906 3.921 18.098 4.163C18.0055 4.405 17.8576 4.62567 17.6543 4.825L4.30592 18H0ZM12.6391 5.525L11.9299 4.8L13.3737 6.225L12.6391 5.525Z" fill="#8E98A7"/>
        </svg>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;