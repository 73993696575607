import React, { SVGProps } from 'react';

const FolderS = (props: SVGProps<SVGSVGElement>) => (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <g clip-path="url(#clip0_6373_15465)">
    <path opacity="0.3" d="M11.17 8L10.59 7.41L9.17 6H4V18H20V8H12H11.17Z" fill="#7F8388"/>
    <path d="M20 6H12L10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6ZM20 18H4V6H9.17L10.58 7.41L11.17 8H20V18Z" fill="#7F8388"/>
  </g>
  <defs>
    <clipPath id="clip0_6373_15465">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>

);

export default FolderS;
