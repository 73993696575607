import React, { SVGProps } from 'react';

const SvgArrowBack = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M11.2308 20L2 11L11.2308 2M3.28205 11L22 11" stroke="#052853" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
	);
};

export default SvgArrowBack;
