import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import toolsReducer from './slices/toolsSlices';
import { updateProjectReducer } from './project';
import { teamReducer } from './slices/team';
import  abacReducer  from './slices/abacpolicy';
import { tabReducer } from './slices/userTabSlice';
// Combine reducers
const rootReducer = combineReducers({
    tools: toolsReducer,
    project: updateProjectReducer,
    team: teamReducer,
    abacpolicyList: abacReducer,
    tab:tabReducer
});


export type RootState = ReturnType<typeof rootReducer>;

// Configure store
const store = configureStore({
    reducer: rootReducer,
});

export default store;
