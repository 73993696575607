import { SET_ACTIVE_TAB } from "../Action/userTabAction";

const initialState = {
    activeTab: 'User',
  };
  

  export const tabReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload,
            };
        default:
            return state;
    }
};