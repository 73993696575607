import React, { SVGProps } from 'react';

const SvgCustomMultiTenant = (props: SVGProps<SVGSVGElement>) => (
<svg width="60" height="52" viewBox="0 0 60 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3918_12090)">
<path d="M53 25V17H43V21H39V35H47V31H49V35H57V25H53ZM43 33H41V31H43V33ZM43 29H41V27H43V29ZM43 25H41V23H43V25ZM47 29H45V27H47V29ZM47 25H45V23H47V25ZM47 21H45V19H47V21ZM51 29H49V27H51V29ZM51 25H49V23H51V25ZM51 21H49V19H51V21ZM55 33H53V31H55V33ZM55 29H53V27H55V29Z" fill="#8E98A7"/>
</g>
<defs>
<clipPath id="clip0_3918_12090">
<rect width="24" height="24" fill="white" transform="translate(36 14)"/>
</clipPath>
</defs>
</svg>

);

export default SvgCustomMultiTenant;
