import React, { SVGProps } from 'react';

const SvgCustomAccessControl = (props: SVGProps<SVGSVGElement>) => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clip-path="url(#clip0_6014_11983)">
<path opacity="0.3" d="M13 7H3V17H13V7ZM8 8C9.07 8 9.95 8.87 9.95 9.95C9.95 11.02 9.08 11.9 8 11.9C6.92 11.9 6.05 11.03 6.05 9.95C6.05 8.87 6.93 8 8 8ZM11.89 16H4.11V15.35C4.11 14.05 6.7 13.4 8 13.4C9.3 13.4 11.89 14.05 11.89 15.35V16Z" fill="#8E98A7"/>
<path d="M21 5H23V19H21V5ZM17 5H19V19H17V5ZM14 19C14.55 19 15 18.55 15 18V6C15 5.45 14.55 5 14 5H2C1.45 5 1 5.45 1 6V18C1 18.55 1.45 19 2 19H14ZM3 7H13V17H3V7Z" fill="#8E98A7"/>
<path d="M7.9998 11.8902C9.07676 11.8902 9.9498 11.0172 9.9498 9.94023C9.9498 8.86328 9.07676 7.99023 7.9998 7.99023C6.92285 7.99023 6.0498 8.86328 6.0498 9.94023C6.0498 11.0172 6.92285 11.8902 7.9998 11.8902Z" fill="#8E98A7"/>
<path d="M8.00035 13.3999C6.70035 13.3999 4.11035 14.0499 4.11035 15.3499V15.9999H11.8904V15.3499C11.8904 14.0499 9.30035 13.3999 8.00035 13.3999Z" fill="#8E98A7"/>
</g>
<defs>
<clipPath id="clip0_6014_11983">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>


);

export default SvgCustomAccessControl;
