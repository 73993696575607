import React, { useState } from 'react';
import { Calendar as DatePicker } from 'react-date-range';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Todo, { ITodoListItem } from '../../../../components/extras/Todo';
import Label from '../../../../components/bootstrap/forms/Label';
import Checks, { ChecksGroup } from '../../../../components/bootstrap/forms/Checks';
import Badge from '../../../../components/bootstrap/Badge';
import Progress from '../../../../components/bootstrap/Progress';
import { TColor } from '../../../../type/color-type';

const CommonDashboardUserIssue = () => {
	const TODO_BADGES: {
		[key: string]: {
			text: string;
			color?: TColor;
		};
	} = {
		NEW: { text: 'New', color: 'success' },
		UPDATE: { text: 'Update', color: 'info' },
		TEST: { text: 'Test', color: 'warning' },
		REPORT: { text: 'Report', color: 'info' },
		PRINT: { text: 'Print', color: 'danger' },
		CONTROL: { text: 'Control', color: 'primary' },
		MEETING: { text: 'Meeting', color: 'secondary' },
	};
	const getBadgeWithText = (text: string): string => {
		return TODO_BADGES[
			// @ts-ignore
			Object.keys(TODO_BADGES).filter((key) => TODO_BADGES[key].text === text)
		];
	};

	/**
	 * To/Do List
	 */
	const [list, setList] = useState<ITodoListItem[]>([
		{
			id: 1,
			status: false,
			title: 'New Products will be added',
			date: dayjs().add(0.5, 'day'),
			badge: TODO_BADGES.NEW,
		},
		{
			id: 2,
			status: false,
			title: 'Cover images will be edited',
			date: dayjs().add(2, 'day'),
			badge: TODO_BADGES.UPDATE,
		},
		// {
		// 	id: 3,
		// 	status: false,
		// 	title: 'Preparing for A/B testing',
		// 	date: dayjs().add(2, 'day'),
		// 	badge: TODO_BADGES.TEST,
		// },
		// {
		// 	id: 4,
		// 	status: false,
		// 	title: 'Google Analytics data will be examined',
		// 	date: dayjs().add(4, 'day'),
		// 	badge: TODO_BADGES.REPORT,
		// },
		// {
		// 	id: 5,
		// 	status: false,
		// 	title: 'Invoices will be issued',
		// 	date: dayjs().add(9, 'day'),
		// 	badge: TODO_BADGES.PRINT,
		// },
		// {
		// 	id: 6,
		// 	status: false,
		// 	title: 'Dependencies check and update',
		// 	date: dayjs().add(15, 'day'),
		// 	badge: TODO_BADGES.CONTROL,
		// },
		// {
		// 	id: 7,
		// 	status: false,
		// 	title: 'End of month meeting',
		// 	date: dayjs().add(32, 'day'),
		// 	badge: TODO_BADGES.MEETING,
		// },
	]);
	

	return (
		<Card style={{width:'400px'}}>
			<CardHeader>
				<CardLabel >
					<CardTitle tag='div' className='h5'>
						Crew
					</CardTitle>
					
				</CardLabel>
			</CardHeader>
			<CardBody isScrollable>
				<Todo list={list} setList={setList} />
			</CardBody>
		</Card>
	);
};

export default CommonDashboardUserIssue;
