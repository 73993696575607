export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE';

export const updateProjectRequest = (projectData) => ({
    type: UPDATE_PROJECT_REQUEST,
    payload: projectData,
});

export const updateProjectSuccess = (projectData) => ({
    type: UPDATE_PROJECT_SUCCESS,
    payload: projectData,
});

export const updateProjectFailure = (error) => ({
    type: UPDATE_PROJECT_FAILURE,
    payload: error,
});
