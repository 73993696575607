import { SET_SELECTED_TEAM } from '../Action/teamAction';

const initialState = {
    selectedTeam: null,
};

export const teamReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_SELECTED_TEAM:
            return {
                ...state,
                selectedTeam: action.payload,
            };
        default:
            return state;
    }
};
