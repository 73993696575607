import React, { SVGProps } from 'react';

const SvgComputer = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg  {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 16.95H6.21C2.84 16.95 2 16.11 2 12.74V6.74003C2 3.37003 2.84 2.53003 6.21 2.53003H16.74C20.11 2.53003 20.95 3.37003 20.95 6.74003" stroke="#8E98A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 21.4699V16.95" stroke="#8E98A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2 12.95H10" stroke="#8E98A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.74219 21.47H10.0022" stroke="#8E98A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.0013 12.8V18.51C22.0013 20.88 21.4113 21.47 19.0413 21.47H15.4913C13.1213 21.47 12.5312 20.88 12.5312 18.51V12.8C12.5312 10.43 13.1213 9.83997 15.4913 9.83997H19.0413C21.4113 9.83997 22.0013 10.43 22.0013 12.8Z" stroke="#8E98A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.2445 18.25H17.2535" stroke="#8E98A7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

	);
};

export default SvgComputer;
