import { Box, styled, Typography } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import Card, { CardBody, CardFooter } from '../../components/bootstrap/Card';
import { LoginHeader, sliderHearedText, VerificationCodeTimerSpan } from '../../commonstyle/styles';
import Logo from '../../assets/loginPageAssets/TenderAILogo.svg';
import { InputOtp } from 'primereact/inputotp';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import AuthServices from '../../services/AuthServices';
import ToastComponent from '../components/toast/toast';
import { useNavigate } from 'react-router-dom';

const LoginButton = styled('button')({
	width: '14rem',
	height: '40px',
	background: '#000000',
	color: 'white',
	border: '0px',
	marginTop: '1rem',
	borderRadius: '15px',
});
const VerifyButton = styled('button')({
	width: '32rem',
	height: '40px',
	background: 'linear-gradient(135deg, rgb(233, 53, 221), rgb(88, 135, 245))',
	color: 'white',
	border: '0px',
	marginTop: '1rem',
	borderRadius: '15px',
});

function VerificationCode() {
	const [token, setTokens] = useState<string>('');
	const initialTime = 60;
	const [timeRemaining, setTimeRemaining] = useState(initialTime);
	const [showToast, setShowToast] = useState(false);
	const [toastSeverity, setToastSeverity] = useState<'success' | 'error' | undefined>(undefined);
	const [toastMessage, setToastMessage] = useState<string>('');
	const [isBtnLoading, setBtnLoading] = useState<boolean>(false);
	const [isResendBtnLoading, setResendBtnLoading] = useState<boolean>(false);
	const [isValid, setValid] = useState<boolean>(false);
	let intervalId: NodeJS.Timeout | null = null;

	const CustomButton = styled('button')({
		background: 'white',
		color: timeRemaining !== 0 ? '#afc7ff' : '#5887F5',
		border: '0px',
		marginLeft: '23rem',
		marginTop: '0rem',
		fontFamily: 'Roboto',
		fontSize: '14px',
		fontWeight: '500',
	});

	const navigate = useNavigate();
	const minutes = Math.floor((timeRemaining % 3600) / 60);
	const seconds = timeRemaining % 60;

	const startTimer = useCallback(() => {
		if (intervalId) {
			clearInterval(intervalId);
		}
		intervalId = setInterval(() => {
			setTimeRemaining((prevTime) => {
				if (prevTime === 0) {
					clearInterval(intervalId!);
					return 0;
				} else {
					return prevTime - 1;
				}
			});
		}, 1000);
	}, []);

	useEffect(() => {
		startTimer();
		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [startTimer]);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
		  if (event.key === 'F5' || (event.ctrlKey && event.key === 'r')) {
			event.preventDefault();
			console.log('Refresh blocked!');
		  }
		};
		window.addEventListener('keydown', handleKeyDown);
		return () => {
		  window.removeEventListener('keydown', handleKeyDown);
		};
	  }, []);

	
	  useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
		  localStorage.setItem('pageRefresh', 'true');
		};
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	  }, []);
	
	  useEffect(() => {
		const pageRefresh = localStorage.getItem('pageRefresh');
		if (pageRefresh === 'true') {
		  localStorage.removeItem('pageRefresh');
		  navigate('/');
		}
	  }, [navigate]);
	
	  const ValidationItem = ({ isValid, text }: { isValid: boolean; text: string }) => (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: '8px',
				color: isValid ? 'green' : 'red',
				marginBottom: '2px',
			}}>
			{isValid ? <i className='pi pi-check'></i> : <i className='pi pi-times'></i>}
			<Typography>{text}</Typography>
		</Box>
	);
	const handleVerifyCode = async () => {
		try {
			setShowToast(false);
			setBtnLoading(true);
			const payload = {
				email: localStorage.getItem('email'),
				verification_code: token,
			};
			const response = await AuthServices.verificationCode(payload);
			if (response?.error) {
				if (
					response?.message ===
					'A verification code has already been sent, Please check your email Or wait a minute to request a new code.'
				) {
					setTimeout(() => {
						navigate('/');
					}, 2000);
				}
				if (
					response?.message ===
					'Incorrect codes entered three times or more than three times in a row; please try again after 5 minutes'
				) {
					setTimeout(() => {
						navigate('/');
					}, 2000);
				}
				setShowToast(true);
				setToastSeverity('error');
				setToastMessage(response?.message);
				setTokens('');
				setValid(true);
			} else {
				setShowToast(true);
				setToastSeverity('success');
				setToastMessage(response?.message);
				setTimeout(() => {
					navigate('/resetPassword');
				}, 5000);
			}
		} catch (error) {
			setValid(true);
			setShowToast(true);
			setToastSeverity('error');
			setToastMessage('Incorrect verification code');
		} finally {
			setBtnLoading(false);
			setTokens('');
		}
	};

	const handleBack = async () => {
		// setForgotPassword(true);
		// // setVerificationCode(false);
		// setResetPassword(false);
	};

	const handleResendCode = async () => {
		try {
			setResendBtnLoading(true);
			setShowToast(false);
			const payload = {
				email: localStorage.getItem('email'),
			};
			const response = await AuthServices.resendCode(payload);
			if (response?.error) {
				setShowToast(true);
				setToastSeverity('error');
				setToastMessage(response?.message);
				setValid(true);
				setResendBtnLoading(false);
			} else {
				setShowToast(true);
				setToastSeverity('success');
				setToastMessage(response?.message);
				setTimeRemaining(initialTime);
				startTimer();
				setResendBtnLoading(false);
			}
		} catch (error) {
			setResendBtnLoading(false);
			setValid(true);
			setShowToast(true);
			setToastSeverity('error');
			setToastMessage('An unexpected error occurred.');
		} finally {
			setResendBtnLoading(false);
		}
	};

	const handleChange = (e: any) => {
		setTokens(e.value);
		setValid(false);
	};

	return (
		<Box
			sx={{
				maxWidth: 'fit-content',
				marginLeft: 'auto',
				marginRight: 'auto',
				paddingTop: '10rem',
			}}>
			{showToast && (
				<ToastComponent
					severity={toastSeverity}
					label={toastSeverity === 'error' ? 'Error' : 'Success'}
					message={toastMessage}
					show={showToast}
				/>
			)}
			<Card style={{ width: '35rem' }}>
				<CardBody>
					<Box sx={{ maxWidth: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}>
						<img src={Logo} alt='' />
					</Box>
					<Box sx={{ marginTop: '3rem' }}>
						<Typography style={LoginHeader}>Verification Code</Typography>
					</Box>
					<div className='row g-3 my-2'>
						<Box sx={{ height: 10 }}>
							<Typography style={sliderHearedText}>Enter Code</Typography>
						</Box>
						<div className='col-12'>
							<FormGroup id='password' label=''>
								<InputOtp
									style={{ gap: 30 }}
									invalid={isValid}
									value={token}
									onChange={handleChange}
									integerOnly
								/>
							</FormGroup>
						</div>
					</div>

					<Box sx={{ display: 'flex' }}>
						<Box>
							<Typography
								style={VerificationCodeTimerSpan}>{`${seconds}s`}</Typography>
						</Box>
						<Box>
							<CustomButton onClick={handleResendCode} disabled={timeRemaining !== 0}>
								{isResendBtnLoading ? (
									<>
										<span
											className='spinner-grow spinner-grow-sm'
											role='status'
											aria-hidden='true'></span>
										Resend Code...
									</>
								) : (
									'Resend Code'
								)}
							</CustomButton>
						</Box>
					</Box>
					<ValidationItem
							isValid={false}
							text='Please Dont Refresh The Browser'
						/>
					{/* <CardFooter> */}
					<Box>
						{/* <LoginButton onClick={handleBack}>Back</LoginButton> */}
						<VerifyButton onClick={handleVerifyCode}>
							{isBtnLoading ? (
								<>
									<span
										className='spinner-grow spinner-grow-sm'
										role='status'
										aria-hidden='true'></span>
									Verifying...
								</>
							) : (
								'Verify'
							)}
						</VerifyButton>
					</Box>
					{/* </CardFooter> */}
				</CardBody>
			</Card>
		</Box>
	);
}

export default VerificationCode;
