// export const summaryPageTopMenu = {
// 	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
// 	bootstrap: {
// 		id: 'bootstrap',
// 		text: 'Bootstrap Components',
// 		path: '#bootstrap',
// 		icon: 'BootstrapFill',
// 		subMenu: null,
// 	},
// 	storybook: {
// 		id: 'storybook',
// 		text: 'Storybook',
// 		path: '#storybook',
// 		icon: 'CustomStorybook',
// 		subMenu: null,
// 	},
// 	formik: {
// 		id: 'formik',
// 		text: 'Formik',
// 		path: '#formik',
// 		icon: 'CheckBox',
// 		subMenu: null,
// 	},
// 	apex: {
// 		id: 'apex',
// 		text: 'Apex Charts',
// 		path: '#apex',
// 		icon: 'AreaChart',
// 		subMenu: null,
// 	},
// };





// const [abac,setAbac] = useState<any[]>([]);



// Get ABAC POLICY FROM LOCALSTORAGE FOR RESTRICTING MENU
const abacPolicyString = localStorage.getItem('abacAccess');
console.log("abac policy:", abacPolicyString);
const abacArray:any[] = [];

if (abacPolicyString) {
    const abacPolicy = JSON.parse(abacPolicyString);	
    if (abacPolicy.hasOwnProperty("abac")) {
		abacArray.push(abacPolicy.abac)
        console.log(abacPolicy.abac);
		// console.log("abacArray:",abacArray)
		console.log("abac:",abacArray);
		

    } else {
        console.log("Email property not found.");
    }
} else {
    console.log("abac policy is null or not found in localStorage.");
}


// abacArray.map((item,index)=>{

// })



export interface pageMenu{ 

	path:string;
	icon:string;
	text:string;
}


export const subResource = {

	company:{
		id:'company',
		path:'companydetails',
		flag:false,
	},
	masterdatacreation:{
		id:'accessControl',
		path:'masterDataCreation',
		flag:false,
	},
	accessControl:{
		id:'masterdatacreation',
		path:'accessControl',
		flag:false,
	}
}


export const dashboardPagesMenu = {

	aistudio: {

		id: 'aistudio',
		text: 'AI Studio',
		path: '/tools',
		icon: "Home",
		flag:  false,
		subMenu: {
			tools: {
				id: 'tools',
				text: 'Tools',
				path: '/tools',
				icon: "Tools",
				subMenu: null,
			},
			agent: {
				id: 'agent',
				text: 'Agent',
				path: '/agent',
				icon: "Agents",
				subMenu: null,
			},
			task: {
				id: 'task',
				text: 'Task',
				path: '/task',
				icon: 'Dashboard',
				subMenu: null,
			},
			crew: {
				id: 'crew',
				text: 'Crew',
				path: '/crew',
				icon: 'Crew',
				subMenu: null,
			},
		},
		
	},
	company: {
		id: 'company',
		text: 'Multi Tenant',
		path: 'companymanagment',
		icon: 'Company',
		flag:false,
	},
	usermanagement: {
		id: 'usermanagement',
		text: 'User Management',
		path: 'usermanagement',
		icon: "Usermanagement",
		subMenu: null,
		flag:false,
	},
	

	tender: {
		id: 'tender',
		text: 'Projects',
		path: 'tender',
		icon: "Tender",
		flag: false,
		tenderDashboard: {
			id: 'tenderDetails',
			text: 'tenderDetails',
			path: 'tender/details',
		},	tender: {
			id: 'tenderDashboard',
			text: 'tenderDashboard',
			path: 'tender/dashboard',
		},
	},

	
	nodetree: {
		id: 'nodetree',
		text: 'Nodetree',
		path: 'nodetree',
		icon: 'Nodetree',
	},

	accessControl: {
		id: 'accessControl',
		text: 'Access Control',
		path: 'accessType',
		icon: 'AccessControl',
		flag:false,
		
	},
	marketPlace: {
		id: 'marketPlace',
		text: 'MarketPlace',
		path: 'marketPlace',
		icon: 'AccessControl',
		flag:false,
		
	},

	creation: {
		id: 'schema',
		text: 'Create Schema',
		path: 'creation',
		icon: "CreateSchema",
		subMenu: null,
		flag:false,
	},
	masterdatacreation: {
		id: 'masterdatacreation',
		text: 'Master Data',
		path: 'masterDataView',
		icon: "MasterData",
		flag:false,
	},

		
	researchstudio: {
		id: 'researchstudio',
		text: 'Research Studio',
		path: 'researchstudio',
		icon: "Book",
		flag:false,
		subMenu: {
			projects: {
				id: 'projects',
				text: 'Projects',
				path: 'researchstudio/projects',
				icon: 'Dashboard',
				subMenu: null,
			}
		},
	},

	
	// dashboard: {
	// 	id: 'dashboard',
	// 	text: 'Dashboardss',
	// 	path: '/',
	// 	icon: 'Dashboard',
	// 	subMenu: null,
	// },






	// dashboardProject: {
	// 	id: 'dashboardProject',
	// 	text: 'Dashboard Projects',
	// 	path: 'project-management/list',
	// 	icon: 'Layers',
	// 	notification: true,
	// 	subMenu: null,
	// },
	// dashboardBooking: {
	// 	id: 'dashboard-booking',
	// 	text: 'Dashboard Booking',
	// 	path: 'dashboard-booking',
	// 	icon: 'DocumentScanner',
	// 	subMenu: null,
	// },
	// crmDashboard: {
	// 	id: 'crmDashboard',
	// 	text: 'CRM Dashboard',
	// 	path: 'crm/dashboard',
	// 	icon: 'InfoOutline',
	// },
	// summary: {
	// 	id: 'summary',
	// 	text: 'Summary',
	// 	path: 'summary',
	// 	icon: 'AccountBox',
	// 	subMenu: null,
	// },
};


export const demoPagesMenu = {
	pages: {
		id: 'pages',
		text: 'Pages',
		icon: 'Extension',
	},
	listPages: {
		id: 'listPages',
		text: 'List Pages',
		path: 'list-pages',
		icon: 'Dvr',
		subMenu: {
			listBoxed: {
				id: 'listBoxed',
				text: 'Boxed List',
				path: 'list-pages/boxed-list',
				icon: 'ViewArray',
			},
			listFluid: {
				id: 'listFluid',
				text: 'Fluid List',
				path: 'list-pages/fluid-list',
				icon: 'ViewDay',
			},
		},
	},
	gridPages: {
		id: 'gridPages',
		text: 'Grid Pages',
		path: 'grid-pages',
		icon: 'Window',
		subMenu: {
			gridBoxed: {
				id: 'gridBoxed',
				text: 'Boxed Grid',
				path: 'grid-pages/boxed',
				icon: 'ViewArray',
			},
			gridFluid: {
				id: 'gridFluid',
				text: 'Fluid Grid',
				path: 'grid-pages/fluid',
				icon: 'ViewDay',
			},
		},
	},
	editPages: {
		id: 'editPages',
		text: 'Edit Pages',
		path: 'edit-pages',
		icon: 'drive_file_rename_outline ',
		subMenu: {
			editModern: {
				id: 'editModern',
				text: 'Modern Edit',
				path: 'edit-pages/modern',
				icon: 'AutoAwesomeMosaic',
				notification: 'primary',
			},
			editBoxed: {
				id: 'editBoxed',
				text: 'Boxed Edit',
				path: 'edit-pages/boxed',
				icon: 'ViewArray',
			},
			editFluid: {
				id: 'editFluid',
				text: 'Fluid Edit',
				path: 'edit-pages/fluid',
				icon: 'ViewDay',
			},
			editWizard: {
				id: 'editWizard',
				text: 'Wizard Edit',
				path: 'edit-pages/wizard',
				icon: 'LinearScale',
			},
			editInCanvas: {
				id: 'editInCanvas',
				text: 'In Canvas Edit',
				path: 'edit-pages/in-canvas',
				icon: 'VerticalSplit',
			},
			editInModal: {
				id: 'editInModal',
				text: 'In Modal Edit',
				path: 'edit-pages/in-modal',
				icon: 'PictureInPicture',
			},
		},
	},
	singlePages: {
		id: 'singlePages',
		text: 'Single Pages',
		path: 'single-pages',
		icon: 'Article',
		subMenu: {
			boxedSingle: {
				id: 'boxedSingle',
				text: 'Boxed',
				path: 'single-pages/boxed',
				icon: 'ViewArray',
			},
			fluidSingle: {
				id: 'fluidSingle',
				text: 'Fluid',
				path: 'single-pages/fluid',
				icon: 'ViewDay',
			},
		},
	},
	pricingTable: {
		id: 'pricingTable',
		text: 'Pricing Table',
		path: 'pricing-table',
		icon: 'Local Offer',
	},

	app: {
		id: 'app',
		text: 'Apps',
		icon: 'Extension',
	},
	projectManagement: {
		id: 'projectManagement',
		text: 'Project Management',
		path: 'project-management',
		icon: 'AutoStories',
		subMenu: {
			list: {
				id: 'list',
				text: 'Projects',
				path: 'project-management/list',
				icon: 'AutoStories',
			},
			itemID: {
				id: 'projectID',
				text: 'projectID',
				path: 'project-management/project',
				hide: true,
			},
			item: {
				id: 'item',
				text: 'Project',
				path: 'project-management/project/1',
				icon: 'Book',
			},
		},
	},
	knowledge: {
		id: 'knowledge',
		text: 'Knowledge',
		path: 'knowledge',
		icon: 'AutoStories',
		subMenu: {
			grid: {
				id: 'grid',
				text: 'Knowledge Grid',
				path: 'knowledge/grid',
				icon: 'AutoStories',
			},
			itemID: {
				id: 'itemID',
				text: 'itemID',
				path: 'knowledge/item',
				hide: true,
			},
			item: {
				id: 'item',
				text: 'Item',
				path: 'knowledge/item/1',
				icon: 'Book',
			},
		},
	},
	
	
	

	
};

// export const demoPagesMenu = {
// 	// pages: {
// 	// 	id: 'pages',
// 	// 	text: 'Pages',
// 	// 	icon: 'Extension',
// 	// },
// 	// listPages: {
// 	// 	id: 'listPages',
// 	// 	text: 'List Pages',
// 	// 	path: 'list-pages',
// 	// 	icon: 'Dvr',
// 	// 	subMenu: {
// 	// 		listBoxed: {
// 	// 			id: 'listBoxed',
// 	// 			text: 'Boxed List',
// 	// 			path: 'list-pages/boxed-list',
// 	// 			icon: 'ViewArray',
// 	// 		},
// 	// 		listFluid: {
// 	// 			id: 'listFluid',
// 	// 			text: 'Fluid List',
// 	// 			path: 'list-pages/fluid-list',
// 	// 			icon: 'ViewDay',
// 	// 		},
// 	// 	},
// 	// },
// 	// gridPages: {
// 	// 	id: 'gridPages',
// 	// 	text: 'Grid Pages',
// 	// 	path: 'grid-pages',
// 	// 	icon: 'Window',
// 	// 	subMenu: {
// 	// 		gridBoxed: {
// 	// 			id: 'gridBoxed',
// 	// 			text: 'Boxed Grid',
// 	// 			path: 'grid-pages/boxed',
// 	// 			icon: 'ViewArray',
// 	// 		},
// 	// 		gridFluid: {
// 	// 			id: 'gridFluid',
// 	// 			text: 'Fluid Grid',
// 	// 			path: 'grid-pages/fluid',
// 	// 			icon: 'ViewDay',
// 	// 		},
// 	// 	},
// 	// },



// 	// editPages: {
// 	// 	id: 'editPages',
// 	// 	text: 'Edit Pages',
// 	// 	path: 'edit-pages',
// 	// 	icon: 'drive_file_rename_outline ',
// 	// 	subMenu: {
// 	// 		// editModern: {
// 	// 		// 	id: 'editModern',
// 	// 		// 	text: 'Modern Edit',
// 	// 		// 	path: 'edit-pages/modern',
// 	// 		// 	icon: 'AutoAwesomeMosaic',
// 	// 		// 	notification: 'primary',
// 	// 		// },
// 	// 		editBoxed: {
// 	// 			id: 'editBoxed',
// 	// 			text: 'Boxed Edit',
// 	// 			path: 'edit-pages/boxed',
// 	// 			icon: 'ViewArray',
// 	// 		},
// 	// 		editProjectDetails: {
// 	// 			id: 'projedctDetails',
// 	// 			text: 'ProjectDetails',
// 	// 			path: 'project/details',
// 	// 			icon: 'ViewArray',
// 	// 		},
// 	// 		// editFluid: {
// 	// 		// 	id: 'editFluid',
// 	// 		// 	text: 'Fluid Edit',
// 	// 		// 	path: 'edit-pages/fluid',
// 	// 		// 	icon: 'ViewDay',
// 	// 		// },
// 	// 		// editWizard: {
// 	// 		// 	id: 'editWizard',
// 	// 		// 	text: 'Wizard Edit',
// 	// 		// 	path: 'edit-pages/wizard',
// 	// 		// 	icon: 'LinearScale',
// 	// 		// },
// 	// 		// editInCanvas: {
// 	// 		// 	id: 'editInCanvas',
// 	// 		// 	text: 'In Canvas Edit',
// 	// 		// 	path: 'edit-pages/in-canvas',
// 	// 		// 	icon: 'VerticalSplit',
// 	// 		// },
// 	// 		// editInModal: {
// 	// 		// 	id: 'editInModal',
// 	// 		// 	text: 'In Modal Edit',
// 	// 		// 	path: 'edit-pages/in-modal',
// 	// 		// 	icon: 'PictureInPicture',
// 	// 		// },
// 	// 	},
// 	// },











// 	// singlePages: {
// 	// 	id: 'singlePages',
// 	// 	text: 'Single Pages',
// 	// 	path: 'single-pages',
// 	// 	icon: 'Article',
// 	// 	subMenu: {
// 	// 		boxedSingle: {
// 	// 			id: 'boxedSingle',
// 	// 			text: 'Boxed',
// 	// 			path: 'single-pages/boxed',
// 	// 			icon: 'ViewArray',
// 	// 		},
// 	// 		fluidSingle: {
// 	// 			id: 'fluidSingle',
// 	// 			text: 'Fluid',
// 	// 			path: 'single-pages/fluid',
// 	// 			icon: 'ViewDay',
// 	// 		},
// 	// 	},
// 	// },
// 	// pricingTable: {
// 	// 	id: 'pricingTable',
// 	// 	text: 'Pricing Table',
// 	// 	path: 'pricing-table',
// 	// 	icon: 'Local Offer',
// 	// },

// 	// app: {
// 	// 	id: 'app',
// 	// 	text: 'Apps',
// 	// 	icon: 'Extension',
// 	// },
// 	// projectManagement: {
// 	// 	id: 'projectManagement',
// 	// 	text: 'Project Management',
// 	// 	path: 'project-management',
// 	// 	icon: 'AutoStories',
// 	// 	subMenu: {
// 	// 		list: {
// 	// 			id: 'list',
// 	// 			text: 'Projects',
// 	// 			path: 'project-management/list',
// 	// 			icon: 'AutoStories',
// 	// 		},
// 	// 		itemID: {
// 	// 			id: 'projectID',
// 	// 			text: 'projectID',
// 	// 			path: 'project-management/project',
// 	// 			hide: true,
// 	// 		},
// 	// 		item: {
// 	// 			id: 'item',
// 	// 			text: 'Project',
// 	// 			path: 'project-management/project/1',
// 	// 			icon: 'Book',
// 	// 		},
// 	// 	},
// 	// },
// 	// knowledge: {
// 	// 	id: 'knowledge',
// 	// 	text: 'Knowledge',
// 	// 	path: 'knowledge',
// 	// 	icon: 'AutoStories',
// 	// 	subMenu: {
// 	// 		grid: {
// 	// 			id: 'grid',
// 	// 			text: 'Knowledge Grid',
// 	// 			path: 'knowledge/grid',
// 	// 			icon: 'AutoStories',
// 	// 		},
// 	// 		itemID: {
// 	// 			id: 'itemID',
// 	// 			text: 'itemID',
// 	// 			path: 'knowledge/item',
// 	// 			hide: true,
// 	// 		},
// 	// 		item: {
// 	// 			id: 'item',
// 	// 			text: 'Item',
// 	// 			path: 'knowledge/item/1',
// 	// 			icon: 'Book',
// 	// 		},
// 	// 	},
// 	// },
// 	// sales: {
// 	// 	id: 'sales',
// 	// 	text: 'Sales',
// 	// 	path: 'sales',
// 	// 	icon: 'Store',
// 	// 	subMenu: {
// 	// 		dashboard: dashboardPagesMenu.dashboard,
// 	// 		salesList: {
// 	// 			id: 'products',
// 	// 			text: 'Sales List',
// 	// 			path: 'sales/sales-list',
// 	// 			icon: 'FactCheck',
// 	// 		},
// 	// 		productsGrid: {
// 	// 			id: 'productsGrid',
// 	// 			text: 'Products Grid',
// 	// 			path: 'sales/grid',
// 	// 			icon: 'CalendarViewMonth',
// 	// 		},
// 	// 		productID: {
// 	// 			id: 'productID',
// 	// 			text: 'productID',
// 	// 			path: 'sales/product',
// 	// 			hide: true,
// 	// 		},
// 	// 		product: {
// 	// 			id: 'product',
// 	// 			text: 'Product',
// 	// 			path: 'sales/product/1',
// 	// 			icon: 'QrCode2',
// 	// 		},
// 	// 		transactions: {
// 	// 			id: 'transactions',
// 	// 			text: 'Transactions',
// 	// 			path: 'sales/transactions',
// 	// 			icon: 'PublishedWithChanges',
// 	// 		},
// 	// 	},
// 	// },
// 	// appointment: {
// 	// 	id: 'appointment',
// 	// 	text: 'Appointment',
// 	// 	path: 'appointment',
// 	// 	icon: 'Today',
// 	// 	subMenu: {
// 	// 		dashboard:'dashboard-booking', //dashboardPagesMenu.dashboardBooking,
// 	// 		calendar: {
// 	// 			id: 'calendar',
// 	// 			text: 'Calendar',
// 	// 			path: 'appointment/calendar',
// 	// 			icon: 'EditCalendar',
// 	// 			notification: true,
// 	// 		},
// 	// 		employeeList: {
// 	// 			id: 'employeeList',
// 	// 			text: 'Employee List',
// 	// 			path: 'appointment/employee-list',
// 	// 			icon: 'PersonSearch',
// 	// 		},
// 	// 		employeeID: {
// 	// 			id: 'employeeID',
// 	// 			text: 'employeeID',
// 	// 			path: 'appointment/employee',
// 	// 			hide: true,
// 	// 		},
// 	// 		employee: {
// 	// 			id: 'employee',
// 	// 			text: 'Employee',
// 	// 			path: 'appointment/employee/1',
// 	// 			icon: 'QrCode2',
// 	// 		},
// 	// 		appointmentList: {
// 	// 			id: 'appointmentList',
// 	// 			text: 'Appointment List',
// 	// 			path: 'appointment/appointment-list',
// 	// 			icon: 'Event',
// 	// 		},
// 	// 	},
// 	// },
// 	// crm: {
// 	// 	id: 'crm',
// 	// 	text: 'CRM',
// 	// 	path: 'crm',
// 	// 	icon: 'Contacts',
// 	// 	subMenu: {
// 	// 		dashboard: {
// 	// 			id: 'dashboard',
// 	// 			text: 'CRM Dashboard',
// 	// 			path: 'crm/dashboard',
// 	// 			icon: 'RecentActors',
// 	// 		},
// 	// 		customersList: {
// 	// 			id: 'customersList',
// 	// 			text: 'Customers',
// 	// 			path: 'crm/customers',
// 	// 			icon: 'PersonSearch',
// 	// 		},
// 	// 		customerID: {
// 	// 			id: 'customerID',
// 	// 			text: 'customerID',
// 	// 			path: 'crm/customer',
// 	// 			hide: true,
// 	// 		},
// 	// 		customer: {
// 	// 			id: 'customer',
// 	// 			text: 'Customer',
// 	// 			path: 'crm/customer/1',
// 	// 			icon: 'Badge',
// 	// 		},
// 	// 		// sales: {
// 	// 		// 	id: 'sales',
// 	// 		// 	text: 'Sales',
// 	// 		// 	path: 'crm/sales',
// 	// 		// 	icon: 'Storefront',
// 	// 		// },
// 	// 		// invoiceID: {
// 	// 		// 	id: 'invoiceID',
// 	// 		// 	text: 'invoiceID',
// 	// 		// 	path: 'crm/invoice',
// 	// 		// 	hide: true,
// 	// 		// },
// 	// 		// invoice: {
// 	// 		// 	id: 'invoice',
// 	// 		// 	text: 'Invoice',
// 	// 		// 	path: 'crm/invoice/1',
// 	// 		// 	icon: 'Receipt',
// 	// 		// },
// 	// 	},
// 	// },
// 	// chat: {
// 	// 	id: 'chat',
// 	// 	text: 'Chat',
// 	// 	path: 'chat',
// 	// 	icon: 'Forum',
// 	// 	subMenu: {
// 	// 		withListChat: {
// 	// 			id: 'withListChat',
// 	// 			text: 'With List',
// 	// 			path: 'chat/with-list',
// 	// 			icon: 'Quickreply',
// 	// 		},
			
// 	// 	},
// 	// },

// 	// auth: {
// 	// 	id: 'auth',
// 	// 	text: 'Auth Pages',
// 	// 	icon: 'Extension',
// 	// // },
// 	// login: {
// 	// 	id: 'login',
// 	// 	text: 'Login',
// 	// 	path: '/',
// 	// 	icon: 'Login',
// 	// },
// 	// signUp: {
// 	// 	id: 'signUp',
// 	// 	text: 'Sign Up',
// 	// 	path: '/sign-up',
// 	// 	icon: 'PersonAdd',
// 	// },
// 	// page404: {
// 	// 	id: 'Page404',
// 	// 	text: '404 Page',
// 	// 	path: 'auth-pages/404',
// 	// 	icon: 'ReportGmailerrorred',
// 	// },
// };





export const pageLayoutTypesPagesMenu = {
	

	asideTypes: {
		id: 'asideTypes',
		text: 'Aside Types',
		path: 'aside-types',
		icon: 'Vertical Split',
		subMenu: {
			defaultAside: {
				id: 'defaultAside',
				text: 'Default Aside',
				path: 'aside-types/default-aside',
				icon: 'ViewQuilt',
			},
			minimizeAside: {
				id: 'minimizeAside',
				text: 'Minimize Aside',
				path: 'aside-types/minimize-aside',
				icon: 'View Compact',
			},
		},
	},
};

export const gettingStartedPagesMenu = {
	gettingStarted: {
		id: 'gettingStarted',
		text: 'Getting Started',
		path: 'getting-started',
		icon: 'Book',
		subMenu: {
			installation: {
				id: 'installation',
				text: 'Installation',
				path: 'getting-started/installation',
				icon: 'BuildCircle',
			},
			dev: {
				id: 'dev',
				text: 'Development',
				path: 'getting-started/development',
				icon: 'DirectionsRun',
			},
			folderStructure: {
				id: 'folderStructure',
				text: 'Folder Structure',
				path: 'getting-started/folder-structure',
				icon: 'SnippetFolder',
			},
			bootstrapVariables: {
				id: 'bootstrapVariables',
				text: 'Bootstrap Variables',
				path: 'getting-started/bootstrap-variables',
				icon: 'SnippetFolder',
			},
			projectStructure: {
				id: 'projectStructure',
				text: 'Project Structure',
				path: 'getting-started/project-structure',
				icon: 'SnippetFolder',
			},
		},
	},
	routes: {
		id: 'routes',
		text: 'Routes & Pages',
		path: 'routes',
		icon: 'AltRoute',
		subMenu: {
			router: {
				id: 'router',
				text: 'Router',
				path: 'routes/router',
				icon: 'Router',
			},
		},
	},
};

export const componentPagesMenu = {
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap',
		icon: 'Extension',
	},
	components: {
		id: 'components',
		text: 'Component',
		path: 'components',
		icon: 'Extension',
		notification: 'success',

		subMenu: {
			accordion: {
				id: 'accordion',
				text: 'Accordion',
				path: 'components/accordion',
				icon: 'ViewDay',
			},
			alert: {
				id: 'alert',
				text: 'Alert',
				path: 'components/alert',
				icon: 'Announcement',
			},
			badge: {
				id: 'badge',
				text: 'Badge',
				path: 'components/badge',
				icon: 'Vibration',
			},
			breadcrumb: {
				id: 'breadcrumb',
				text: 'Breadcrumb',
				path: 'components/breadcrumb',
				icon: 'AddRoad',
			},
			button: {
				id: 'button',
				text: 'Button',
				path: 'components/button',
				icon: 'SmartButton',
			},
			buttonGroup: {
				id: 'buttonGroup',
				text: 'Button Group',
				path: 'components/button-group',
				icon: 'Splitscreen',
			},
			card: {
				id: 'card',
				text: 'Card',
				path: 'components/card',
				icon: 'Crop32',
			},
			carousel: {
				id: 'carousel',
				text: 'Carousel',
				path: 'components/carousel',
				icon: 'RecentActors',
			},
			// Close
			collapse: {
				id: 'collapse',
				text: 'Collapse',
				path: 'components/collapse',
				icon: 'UnfoldLess',
			},
			dropdowns: {
				id: 'dropdowns',
				text: 'Dropdowns',
				path: 'components/dropdowns',
				icon: 'Inventory',
			},
			listGroup: {
				id: 'listGroup',
				text: 'List Group',
				path: 'components/list-group',
				icon: 'ListAlt',
			},
			modal: {
				id: 'modal',
				text: 'Modal',
				path: 'components/modal',
				icon: 'PictureInPicture',
			},
			navsTabs: {
				id: 'navsTabs',
				text: 'Navs & Tabs',
				path: 'components/navs-and-tabs',
				icon: 'PivotTableChart',
			},
			// Navbar
			offcanvas: {
				id: 'offcanvas',
				text: 'Offcanvas',
				path: 'components/offcanvas',
				icon: 'VerticalSplit',
			},
			pagination: {
				id: 'pagination',
				text: 'Pagination',
				path: 'components/pagination',
				icon: 'Money',
			},
			popovers: {
				id: 'popovers',
				text: 'Popovers',
				path: 'components/popovers',
				icon: 'Assistant',
			},
			progress: {
				id: 'progress',
				text: 'Progress',
				path: 'components/progress',
				icon: 'HourglassTop',
			},
			scrollspy: {
				id: 'scrollspy',
				text: 'Scrollspy',
				path: 'components/scrollspy',
				icon: 'KeyboardHide',
			},
			spinners: {
				id: 'spinners',
				text: 'Spinners',
				path: 'components/spinners',
				icon: 'RotateRight',
			},
			table: {
				id: 'table',
				text: 'Table',
				path: 'components/table',
				icon: 'TableChart',
			},
			toasts: {
				id: 'toasts',
				text: 'Toasts',
				path: 'components/toasts',
				icon: 'RotateRight',
			},
			tooltip: {
				id: 'tooltip',
				text: 'Tooltip',
				path: 'components/tooltip',
				icon: 'Assistant',
			},
		},
	},
	forms: {
		id: 'forms',
		text: 'Forms',
		path: 'forms',
		icon: 'CheckBox',
		notification: 'success',
		subMenu: {
			formGroup: {
				id: 'formGroup',
				text: 'Form Group',
				path: 'forms/form-group',
				icon: 'Source',
			},
			formControl: {
				id: 'formControl',
				text: 'Form Controls',
				path: 'forms/form-controls',
				icon: 'Create',
			},
			select: {
				id: 'select',
				text: 'Select',
				path: 'forms/select',
				icon: 'Checklist',
			},
			checksAndRadio: {
				id: 'checksAndRadio',
				text: 'Checks & Radio',
				path: 'forms/checks-and-radio',
				icon: 'CheckBox',
			},
			range: {
				id: 'range',
				text: 'Range',
				path: 'forms/range',
				icon: 'HdrStrong',
			},
			inputGroup: {
				id: 'inputGroup',
				text: 'Input Group',
				path: 'forms/input-group',
				icon: 'PowerInput',
			},
			validation: {
				id: 'validation',
				text: 'Validation',
				path: 'forms/validation',
				icon: 'VerifiedUser',
			},
			wizard: {
				id: 'wizard',
				text: 'Wizard',
				path: 'forms/wizard',
				icon: 'LinearScale',
			},
		},
	},
	content: {
		id: 'content',
		text: 'Content',
		path: 'content',
		icon: 'format_size',
		subMenu: {
			typography: {
				id: 'typography',
				text: 'Typography',
				path: 'content/typography',
				icon: 'text_fields',
			},
			images: {
				id: 'images',
				text: 'Images',
				path: 'content/images',
				icon: 'Image ',
			},
			tables: {
				id: 'tables',
				text: 'Tables',
				path: 'content/tables',
				icon: 'table_chart',
			},
			figures: {
				id: 'figures',
				text: 'Figures',
				path: 'content/figures',
				icon: 'Photo Library ',
			},
		},
	},
	utilities: {
		id: 'utilities',
		text: 'Utilities',
		path: 'utilities',
		icon: 'Support',
		subMenu: {
			api: {
				id: 'api',
				text: 'API',
				path: 'utilities/api',
				icon: 'Api',
			},
			background: {
				id: 'background',
				text: 'Background',
				path: 'utilities/background',
				icon: 'FormatColorFill',
			},
			borders: {
				id: 'borders',
				text: 'Borders',
				path: 'utilities/borders',
				icon: 'BorderStyle',
			},
			colors: {
				id: 'colors',
				text: 'Colors',
				path: 'utilities/colors',
				icon: 'InvertColors',
			},
			display: {
				id: 'display',
				text: 'Display',
				path: 'utilities/display',
				icon: 'LaptopMac',
			},
			flex: {
				id: 'flex',
				text: 'Flex',
				path: 'utilities/flex',
				icon: 'SettingsOverscan',
			},
			float: {
				id: 'float',
				text: 'Float',
				path: 'utilities/float',
				icon: 'ViewArray',
			},
			interactions: {
				id: 'interactions',
				text: 'Interactions',
				path: 'utilities/interactions',
				icon: 'Mouse',
			},
			overflow: {
				id: 'overflow',
				text: 'Overflow',
				path: 'utilities/overflow',
				icon: 'TableRows',
			},
			position: {
				id: 'position',
				text: 'Position',
				path: 'utilities/position',
				icon: 'Adjust',
			},
			shadows: {
				id: 'shadows',
				text: 'Shadows',
				path: 'utilities/shadows',
				icon: 'ContentCopy',
			},
			sizing: {
				id: 'sizing',
				text: 'Sizing',
				path: 'utilities/sizing',
				icon: 'Straighten',
			},
			spacing: {
				id: 'spacing',
				text: 'Spacing',
				path: 'utilities/spacing',
				icon: 'SpaceBar',
			},
			text: {
				id: 'text',
				text: 'Text',
				path: 'utilities/text',
				icon: 'TextFields',
			},
			verticalAlign: {
				id: 'vertical-align',
				text: 'Vertical Align',
				path: 'utilities/vertical-align',
				icon: 'VerticalAlignCenter',
			},
			visibility: {
				id: 'visibility',
				text: 'Visibility',
				path: 'utilities/visibility',
				icon: 'Visibility',
			},
		},
	},
	extra: {
		id: 'extra',
		text: 'Extra Library',
		icon: 'Extension',
		path: undefined,
	},
	icons: {
		id: 'icons',
		text: 'Icons',
		path: 'icons',
		icon: 'Grain',
		notification: 'success',
		subMenu: {
			icon: {
				id: 'icon',
				text: 'Icon',
				path: 'icons/icon',
				icon: 'Lightbulb',
			},
			material: {
				id: 'material',
				text: 'Material',
				path: 'icons/material',
				icon: 'Verified',
			},
		},
	},
	charts: {
		id: 'charts',
		text: 'Charts',
		path: 'charts',
		icon: 'AreaChart',
		notification: 'success',
		subMenu: {
			chartsUsage: {
				id: 'chartsUsage',
				text: 'General Usage',
				path: 'charts/general-usage',
				icon: 'Description',
			},
			chartsSparkline: {
				id: 'chartsSparkline',
				text: 'Sparkline',
				path: 'charts/sparkline',
				icon: 'AddChart',
			},
			chartsLine: {
				id: 'chartsLine',
				text: 'Line',
				path: 'charts/line',
				icon: 'ShowChart',
			},
			chartsArea: {
				id: 'chartsArea',
				text: 'Area',
				path: 'charts/area',
				icon: 'AreaChart',
			},
			chartsColumn: {
				id: 'chartsColumn',
				text: 'Column',
				path: 'charts/column',
				icon: 'BarChart',
			},
			chartsBar: {
				id: 'chartsBar',
				text: 'Bar',
				path: 'charts/bar',
				icon: 'StackedBarChart',
			},
			chartsMixed: {
				id: 'chartsMixed',
				text: 'Mixed',
				path: 'charts/mixed',
				icon: 'MultilineChart',
			},
			chartsTimeline: {
				id: 'chartsTimeline',
				text: 'Timeline',
				path: 'charts/timeline',
				icon: 'WaterfallChart',
			},
			chartsCandleStick: {
				id: 'chartsCandleStick',
				text: 'Candlestick',
				path: 'charts/candlestick',
				icon: 'Cake',
			},
			chartsBoxWhisker: {
				id: 'chartsBoxWhisker',
				text: 'Box Whisker',
				path: 'charts/box-whisker',
				icon: 'SportsMma',
			},
			chartsPieDonut: {
				id: 'chartsPieDonut',
				text: 'Pie & Donut',
				path: 'charts/pie-donut',
				icon: 'PieChart',
			},
			chartsRadar: {
				id: 'chartsRadar',
				text: 'Radar',
				path: 'charts/radar',
				icon: 'BrightnessLow',
			},
			chartsPolar: {
				id: 'chartsPolar',
				text: 'Polar',
				path: 'charts/polar',
				icon: 'TrackChanges',
			},
			chartsRadialBar: {
				id: 'chartsRadialBar',
				text: 'Radial Bar',
				path: 'charts/radial-bar',
				icon: 'DonutLarge',
			},
			chartsBubble: {
				id: 'chartsBubble',
				text: 'Bubble',
				path: 'charts/bubble',
				icon: 'BubbleChart',
			},
			chartsScatter: {
				id: 'chartsScatter',
				text: 'Scatter',
				path: 'charts/scatter',
				icon: 'ScatterPlot',
			},
			chartsHeatMap: {
				id: 'chartsHeatMap',
				text: 'Heat Map',
				path: 'charts/heat-map',
				icon: 'GridOn',
			},
			chartsTreeMap: {
				id: 'chartsTreeMap',
				text: 'Tree Map',
				path: 'charts/tree-map',
				icon: 'AccountTree',
			},
		},
	},
	notification: {
		id: 'notification',
		text: 'Notification',
		path: 'notifications',
		icon: 'NotificationsNone',
	},
	hooks: {
		id: 'hooks',
		text: 'Hooks',
		path: 'hooks',
		icon: 'Anchor',
	},
};



console.log("dashBoardPagesMenu:",dashboardPagesMenu);

// abacArray[0].map((item:any,index:number)=>{

// 	if()
// })

// let flagg = false;


// STORE ABAC POLICY IN AN ARRAY AND SET THE FLAG BASED ON ABAC CONDITIONS
abacArray[0]?.map((item:any,index:number)=>{
	

for (const [key, value] of Object.entries(dashboardPagesMenu)) {


	if(item.resource_type===(dashboardPagesMenu[key as keyof typeof dashboardPagesMenu] as any).text){
		(dashboardPagesMenu[key as keyof typeof dashboardPagesMenu] as any).flag=true;
		if(subResource[key as keyof typeof subResource]){
		(subResource[key as keyof typeof subResource].flag) = true;
	}
}
  }	

})

// console.log("subResource:",subResource['masterdata'].flag);

// console.log("dashboardmenu:",dashboardPagesMenu);
// console.log("flag value:",flagg);

// console.log("Usermanagement daash:",dashboardPagesMenu.usermanagement.text);

export const productsExampleMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
