import React, { useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';

interface ToastProps {
	severity: any;
	label: string;
	message: string;
	show: boolean;
	onShowComplete?: () => void;
	position?: any;
}

const ToastComponent: React.FC<ToastProps> = ({ severity, label, message, show, position }) => {
	const toast = useRef<Toast>(null);

	useEffect(() => {
		if (show) {
			toast.current?.show({ severity, summary: label, detail: message, life: 3000 });
		}
	}, [show, severity, label, message]);

	return <Toast ref={toast} position={position || 'top-right'} />;
};

export default ToastComponent;
