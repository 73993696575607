import { UPDATE_PROJECT_REQUEST, UPDATE_PROJECT_SUCCESS, UPDATE_PROJECT_FAILURE } from './Action/projectAction';

const initialState = {
    loading: false,
    error: null,
    projectData: [],
};

export const updateProjectReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case UPDATE_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                projectData: action.payload,
            };
        case UPDATE_PROJECT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
