import React, { SVGProps } from 'react';

const SvgPlayForWork = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="1.25" y="0.75" width="22.5" height="22.5" rx="3.25" fill="#DFE5F1" stroke="#8E98A7" stroke-width="1.5" />
			<path d="M5.67653 19.6H19.3241C20.1443 19.6 20.6157 18.6671 20.1292 18.0068L16.454 13.019C16.0709 12.4991 15.3027 12.4732 14.8855 12.9663L12.0479 16.3198C11.6548 16.7844 10.9414 16.7931 10.5371 16.3382L8.9252 14.5248C8.50215 14.0489 7.74795 14.0843 7.37138 14.5978L4.87012 18.0086C4.38574 18.6692 4.85743 19.6 5.67653 19.6Z" fill="#8E98A7" />
		</svg>

	);
};

export default SvgPlayForWork;
