import React, { SVGProps } from 'react';

const SvgCustomAgents = (props: SVGProps<SVGSVGElement>) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clip-path="url(#clip0_3918_12076)">
<path opacity="0.3" d="M5 5H9V9H5V5ZM5 15H9V19H5V15ZM15 15H19V19H15V15ZM15 5H19V9H15V5Z" fill="#A4A4A4"/>
<path d="M3 21H11V13H3V21ZM5 15H9V19H5V15ZM3 11H11V3H3V11ZM5 5H9V9H5V5ZM13 21H21V13H13V21ZM15 15H19V19H15V15ZM13 3V11H21V3H13ZM19 9H15V5H19V9Z" fill="#A4A4A4"/>
</g>
<defs>
<clipPath id="clip0_3918_12076">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

);

export default SvgCustomAgents;
