import React, { SVGProps } from 'react';

const SvgCustomUserManagement = (props: SVGProps<SVGSVGElement>) => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clip-path="url(#clip0_6022_23646)">
<path opacity="0.3" d="M19 5H5V19H19V5ZM12 6C13.65 6 15 7.35 15 9C15 10.65 13.65 12 12 12C10.35 12 9 10.65 9 9C9 7.35 10.35 6 12 6ZM18 18H6V16.47C6 13.97 9.97 12.89 12 12.89C14.03 12.89 18 13.97 18 16.47V18Z" fill="#8E98A7"/>
<path d="M20.66 3.88C20.52 3.67 20.33 3.48 20.12 3.34C20.01 3.27 19.9 3.21 19.78 3.16C19.54 3.06 19.28 3 19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C19.28 21 19.54 20.94 19.78 20.84C19.9 20.79 20.01 20.73 20.12 20.66C20.33 20.52 20.52 20.33 20.66 20.12C20.87 19.8 21 19.41 21 19V5C21 4.59 20.87 4.2 20.66 3.88ZM12 2.75C12.22 2.75 12.41 2.85 12.55 3C12.67 3.13 12.75 3.31 12.75 3.5C12.75 3.91 12.41 4.25 12 4.25C11.59 4.25 11.25 3.91 11.25 3.5C11.25 3.31 11.33 3.13 11.45 3C11.59 2.85 11.78 2.75 12 2.75ZM19 19H5V5H19V19ZM12 12C13.65 12 15 10.65 15 9C15 7.35 13.65 6 12 6C10.35 6 9 7.35 9 9C9 10.65 10.35 12 12 12ZM12 10C11.45 10 11 9.55 11 9C11 8.45 11.45 8 12 8C12.55 8 13 8.45 13 9C13 9.55 12.55 10 12 10ZM12 12.88C9.97 12.88 6 13.96 6 16.46V18H18V16.47C18 13.96 14.03 12.88 12 12.88ZM8.31 16C9 15.44 10.69 14.88 12 14.88C13.31 14.88 15.01 15.44 15.69 16H8.31Z" fill="#8E98A7"/>
</g>
<defs>
<clipPath id="clip0_6022_23646">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

);

export default SvgCustomUserManagement;
