import React from 'react';
import { RouteProps } from 'react-router-dom';
import {
	componentPagesMenu,
	dashboardPagesMenu,
	demoPagesMenu,
	gettingStartedPagesMenu,
	
} from '../menu';

import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import DashboardBookingHeader from '../pages/_layout/_headers/DashboardBookingHeader';
import ProfilePageHeader from '../pages/_layout/_headers/ProfilePageHeader';
import SummaryHeader from '../pages/_layout/_headers/SummaryHeader';
import ProductsHeader from '../pages/_layout/_headers/ProductsHeader';
import ProductListHeader from '../pages/_layout/_headers/ProductListHeader';
import PageLayoutHeader from '../pages/_layout/_headers/PageLayoutHeader';
import ComponentsHeader from '../pages/_layout/_headers/ComponentsHeader';
import FormHeader from '../pages/_layout/_headers/FormHeader';
import ChartsHeader from '../pages/_layout/_headers/ChartsHeader';
import ContentHeader from '../pages/_layout/_headers/ContentHeader';
import UtilitiesHeader from '../pages/_layout/_headers/UtilitiesHeader';
import IconHeader from '../pages/_layout/_headers/IconHeader';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';
import DocumentationHeader from '../pages/_layout/_headers/DocumentationHeader';
import TopHeaderComponent from '../pages/_layout/_headers/TopHeaderComponent';
import ForgotPassword from '../pages/login/ForgotPassword';

const headers: RouteProps[] = [
	{ path: '/', element: null },
	{ path: '/', element: null },
	{ path: '/', element: null },
	{ path: '/', element: null },
	{ path: '/', element: null },
	{ path: '/', element: null },
	{ path: '/forgotPassword', element: null },
	{ path: '/verificationCode', element: null },
	{ path: '/resetPassword', element: null },
	{ path:dashboardPagesMenu.accessControl.path, element: <TopHeaderComponent /> },
	{
		path: dashboardPagesMenu.usermanagement.path,
		element:<TopHeaderComponent /> ,
	},
	{ path: dashboardPagesMenu.accessControl.path,
		element:<TopHeaderComponent />
	 },
	{
		path: dashboardPagesMenu.masterdatacreation.path,
		element:<TopHeaderComponent />,
	},
	{
		path: dashboardPagesMenu.masterdatacreation.path,
		element:<TopHeaderComponent />,
	},
	{
		path: '/profile',
		element: <TopHeaderComponent />,
	},
	{
		path: dashboardPagesMenu.company.path,
		element: <TopHeaderComponent />,
	},
	{
		path: '/companydetails',
		element: <TopHeaderComponent />,
	},
	{
		path: `/accessControl`,
		element: <TopHeaderComponent />,
	},
	{
		path: `/creation`,
		element: <TopHeaderComponent />,
	},
	{
		path: `/nodetree`,
		element: <TopHeaderComponent />,
	},
	{
		path: `/rolesdetails`,
		element: <TopHeaderComponent />,
	},
	{
		path: '/teamdetails',
		element: <TopHeaderComponent />,
	},
	{
		path: '/',
		element: <ProfilePageHeader />,
	},
	{
		path: '/',
		element: <ProfilePageHeader />,
	},
	{
		path: '/',
		element: <ProfilePageHeader />,
	},
	{
		path: '/',
		element: <ProfilePageHeader />,
	},
	{
		path:'/',
		element: <ProfilePageHeader />,
	},
	{
		path: `/`,
		element: <ProfilePageHeader />,
	},
	{
		path: '/',
		element: <SummaryHeader />,
	},
	{
		path: '/',
		element: <ProductsHeader />,
	},
	{
		path: '/',
		element: <ProductsHeader />,
	},
	{
		path: '/',
		element: <ProductListHeader />,
	},
	{
		path: '/',
		element: <ProductListHeader />,
	},
	{
		path: '/',
		element: <ProductListHeader />,
	},
	{
		path: '/',
		element: <ProductListHeader />,
	},
	{
		path: `/`,
		element: <PageLayoutHeader />,
	},
	{
		path: `/`,
		element: <PageLayoutHeader />,
	},
	{
		path: `/`,
		element: <PageLayoutHeader />,
	},
	{
		path: `${componentPagesMenu.components.path}/*`,
		element: <ComponentsHeader />,
	},
	{
		path: `${componentPagesMenu.forms.path}/*`,
		element: <FormHeader />,
	},
	{
		path: `${componentPagesMenu.charts.path}/*`,
		element: <ChartsHeader />,
	},
	{
		path: `${componentPagesMenu.content.path}/*`,
		element: <ContentHeader />,
	},
	{
		path: `${componentPagesMenu.utilities.path}/*`,
		element: <UtilitiesHeader />,
	},
	{
		path: `${componentPagesMenu.icons.path}/*`,
		element: <IconHeader />,
	},
	{
		path: `${gettingStartedPagesMenu.gettingStarted.path}/*`,
		element: <DocumentationHeader />,
	},
	{
		path: `${gettingStartedPagesMenu.routes.path}/*`,
		element: <DocumentationHeader />,
	},
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
