import { Box, styled, Typography } from '@mui/material';
import React, { useState } from 'react';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Logo from '../../assets/loginPageAssets/TenderAILogo.svg';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import { LoginHeader, sliderBodyText, sliderHearedText } from '../../commonstyle/styles';
import Input from '../../components/bootstrap/forms/Input';
import AuthServices from '../../services/AuthServices';
import ToastComponent from '../components/toast/toast';

const SubmitButton = styled('button')({
	width: '32rem',
	height: '3rem',
	background: 'linear-gradient(135deg, rgb(233, 53, 221), rgb(88, 135, 245))',
	color: 'white',
	border: '0px',
	marginTop: '1rem',
	borderRadius: '13px',
});

function CreatePassword({setLoginIsVisible,setCreatePassword}:any) {
	const [password, setPassword] = useState<string>('');
	const [confirm_password, setConfirm_Password] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [showToast, setShowToast] = useState(false);
	const [toastSeverity, setToastSeverity] = useState<'success' | 'error' | undefined>(undefined);
	const [toastMessage, setToastMessage] = useState<string>('');
	const [isBtnLoading, setBtnLoading] = useState<boolean>(false);
	// Validation conditions
	const isLengthValid = password.length >= 8 && password.length <= 12;
	const hasUpperCase = /[A-Z]/.test(password);
	const hasLowerCase = /[a-z]/.test(password);
	const hasNumber = /\d/.test(password);
	const containsSpecialChar = /[^a-zA-Z0-9]/.test(password);

	const ValidationItem = ({ isValid, text }: { isValid: boolean; text: string }) => (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: '8px',
				color: isValid ? 'green' : 'red',
				marginBottom: '2px',
			}}>
			{isValid ? <i className='pi pi-check'></i> : <i className='pi pi-times'></i>}
			<Typography>{text}</Typography>
		</Box>
	);

	const handleChangePassword = (e: any) => {
		setPassword(e.target.value);
	};
	const handleChangeConfirmPassword = (e: any) => {
		setConfirm_Password(e.target.value);
	};

	const handleSend = async () => {
		if (password !== confirm_password) {
			alert('Password and Confirm Passwords dont match');
		}
		try {
			setBtnLoading(true);
			setShowToast(false);
			const payload = {
				email: localStorage.getItem('email'),
				password: password,
				confirm_password: confirm_password,
			};
			const response = await AuthServices.createPassword(payload);
			if (response?.error) {
				setBtnLoading(false);
				setShowToast(true);
				setToastSeverity('error');
				setToastMessage(response?.message);
			} else {
				setBtnLoading(false);
				setShowToast(true);
				setToastSeverity('success');
				setToastMessage(response?.message);
				setTimeout(() => {
					setLoginIsVisible(true);
					setCreatePassword(false);
				}, 5000); 
				
			}
		
		} catch (error) {
			setBtnLoading(false);
			// setShowToast(true);
			// setToastSeverity('error');
			// setToastMessage('An unexpected error occurred.');
		} finally {
			setBtnLoading(false);
			setLoading(false);
		}
	};
	return (
		<Box
			sx={{
				maxWidth: 'fit-content',
				marginLeft: 'auto',
				marginRight: 'auto',
				paddingTop: '10rem',
			}}>
			{showToast && (
				<ToastComponent
					severity={toastSeverity}
					label={toastSeverity === 'error' ? 'Error' : 'Success'}
					message={toastMessage}
					show={showToast}
				/>
			)}
			<Card style={{ width: '35rem' }}>
				<CardBody>
					<Box sx={{ maxWidth: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}>
						<img src={Logo} alt='' />
					</Box>
					<Box sx={{ marginTop: '3rem' }}>
						<Typography style={LoginHeader}>Create Password</Typography>
					</Box>
					<div className='row g-3 my-2'>
						<Box sx={{ height: 10 }}>
							<Typography style={sliderHearedText}>Password</Typography>
						</Box>
						<div className='col-12'>
							<FormGroup id='password' label=''>
								<Input
									type='password'
									name='password'
									placeholder=''
									onChange={handleChangePassword}
									// onBlur={userformik.handleBlur}
									value={password}
									// isValid={userformik?.isValid}
									// invalidFeedback={userformik?.errors?.full_name}
									// isTouched={userformik?.touched?.full_name}
									// validFeedback='Looks good!'
									style={{
										...sliderBodyText,
									}}
								/>
							</FormGroup>
						</div>
						<Box sx={{ height: 10 }}>
							<Typography style={sliderHearedText}>Confirm Password</Typography>
						</Box>
						<div className='col-12'>
							<FormGroup id='confirm_password' label=''>
								<Input
									type='password'
									name='confirm_password'
									placeholder=''
									onChange={handleChangeConfirmPassword}
									// onBlur={userformik.handleBlur}
									value={confirm_password}
									// isValid={userformik?.isValid}
									// invalidFeedback={userformik?.errors?.full_name}
									// isTouched={userformik?.touched?.full_name}
									// validFeedback='Looks good!'
									style={{
										...sliderBodyText,
									}}
								/>
							</FormGroup>
						</div>

						<Box>
							<SubmitButton onClick={handleSend}>
								{' '}
								{isBtnLoading ? (
									<>
										<span
											className='spinner-grow spinner-grow-sm'
											role='status'
											aria-hidden='true'></span>
										Saving...
									</>
								) : (
									'Save'
								)}
							</SubmitButton>
						</Box>
					</div>
					<Box sx={{ marginTop: '2rem', marginLeft: '2rem' }}>
						<ValidationItem
							isValid={isLengthValid}
							text='Password must be 8-12 characters long'
						/>
						<ValidationItem
							isValid={hasUpperCase}
							text='Password should contain atleast one upper case letter'
						/>
						<ValidationItem
							isValid={hasLowerCase}
							text='Password should contain atleast one lower case letter'
						/>
						<ValidationItem
							isValid={hasNumber}
							text='Password must have at least one number'
						/>
						<ValidationItem
							isValid={containsSpecialChar}
							text='Password must have at least one Special characters '
						/>
					</Box>
				</CardBody>
			</Card>
		</Box>
	);
}

export default CreatePassword;
