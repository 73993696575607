import { color } from 'framer-motion';
import { colour } from './commonColour';
import { font } from './constName';
import { Padding, WidthFull } from '@mui/icons-material';
import { borderRadius } from '@mui/system';
import { Height } from '../components/icon/material-icons';
import { styled } from '@mui/material/styles';
import Button from '../components/bootstrap/Button';

export const multiSelect = {
	// color: '#000 !important',
	// borderRadius: '10px',
	// background: 'var(--Bg-gray, #F8F9FA)',
	// boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.04) inset',
	// padding: '5px',
	// minWidth: '21vw',

	color: '#000 !important',
	borderRadius: '10px',
	background: 'var(--Bg-gray, #F8F9FA)',
	boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.04) inset',
	padding: '5px',
	minWidth: '21vw',
};

export const CountBadge = styled('span')({
	display: 'inline-block',
	width: '24px',
	height: '24px',
	backgroundColor: '#0072CE',
	color: 'white',
	textAlign: 'center',
	lineHeight: '24px',
	fontSize: '14px',
	borderRadius: '50%',
});


export const reNameBox = {
	display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
	width: '130px',
	height: '38px',
	borderRadius: '13px',
	border: '1px solid #5887F5',
	backgroundColor: '#fff', 
	fontSize: '16px',
	color: '#5887F5', 
	padding: '7px 22px 5px 11px',
	gap: '10px',
	fontFamily: 'Roboto, sans-serif',
};



export const blueButton = styled(Button)({
	backgroundColor: '#fff',
	borderRadius: '25px',
	color: '#5887F5',
	height: '45px',
	width: '100%',
	marginTop: '2rem',
	fontSize: '14px',
	fontWeight: '400',
	border: '2px solid #5887F5',
	'&:hover': {
		backgroundColor: '#fff',
		color: '#5887F5',
		border: '2px solid #5887F5',
	},
	justifyContent: 'center',
	alignItems: 'center',
	fontFamily: 'Roboto, sans-serif',
});

export const StyledStar = styled('span')({
	color: 'red',
	paddingLeft: '5px',
});

export const SecondaryButton = styled(Button)({
	backgroundColor: '#fff',
	borderRadius: '25px',
	color: '#5887F5',
	height: '45px',
	width: '100%',
	marginTop: '2rem',
	fontSize: '14px',
	fontWeight: '400',
	border: '2px solid #5887F5',
	'&:hover': {
		backgroundColor: '#fff',
		color: '#5887F5',
		border: '2px solid #5887F5',
	},
	justifyContent: 'center',
	alignItems: 'center',
	fontFamily: 'Roboto, sans-serif',
});

export const tableBodytext = {
	fontSize: font.fourteen,
	color: colour.tenderSubHeader,
	fontWeight: font.fontWeightfour,
	fontFamily: font.fontFamily,
};

export const tableBlueText = {
	fontSize: font.fourteen,
	color: colour.projectName,
	fontWeight: font.fontWeightfour,
	fontFamily: font.fontFamily,
};

export const textcommonStyle = {
	fontFamily: font.fontFamily,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightfive,
	color: '#233145',
	height: '50px',
};

export const textcommonlistStyle = {
	fontFamily: font.fontFamily,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightfive,
	color: '#233145',
	height: '50px',
};
export const droupdowntextcommonStyle = {
	fontFamily: font.fontFamily,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightsix,
	color: '#233145',
	//height:"50px"
};

export const userbasicdetailtextcommonStyle = {
	fontFamily: font.fontFamily,
	fontSize: font.sixteen,
	fontStyle: 'normal',
	fontWeight: font.fontWeightsix,
	color: '#8E98A7',
};

export const userbasicdetailiconStyle = {
	width: '28px',
	height: '28px',
};

export const StatusInactive = styled('span')({
	background: '#F35C2C',
	color: 'white',
	padding: '6px 8px 6px 8px',
	width: 'auto',
	borderRadius: '20px',
	fontSize: '12px',
});

export const StatusSpanElement = styled('span')({
	background: '#46BCAA',
	color: 'white',
	padding: '6px 8px 6px 8px',
	width: 'auto',
	borderRadius: '20px',
	fontSize: '12px',
});

export const StatusSpanElementTwo = styled('span')({
	background: 'rgb(243, 84, 33)',
	color: 'white',
	padding: '6px 9px 7px 9px',
	width: '88px',
	borderRadius: '20px',
	fontSize: '15px',
});

export const userProfileHeaderText = {
	fontFamily: font.poppins,
	fontSize: font.twentyfour,
	fontStyle: 'normal',
	fontWeight: font.fontWeightSeven,
	color: '#052853',
};

export const ProfileTableHeaders = {
	fontFamily: font.fontFamily,
	fontSize: font.sixteen,
	fontStyle: 'normal',
	fontWeight: font.fontWeightSeven,
	color: '#052853',
};

export const submitbuttonStyle = {
	marginLeft: font.ten,
	background: 'linear-gradient(135deg, #E935DD, #5887F5)',
	color: 'white',
	fontWeight: font.fontWeightsix,
	width: '92%',
	fontFamily: font.fontFamily,
	borderRadius: '10px',
	height: '40px',
};

export const usersubmitbuttonStyle = {
	marginLeft: font.ten,
	background: 'linear-gradient(135deg, #E935DD, #5887F5)',
	color: 'white',
	fontWeight: font.fontWeightsix,
	width: '100%',
	fontFamily: font.fontFamily,
	height: '40px',
};

export const updateUserbuttonStyle = {
	background: 'linear-gradient(135deg, #E935DD, #5887F5)',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginLeft: font.ten,
	marginBottom: '1rem',
	color: 'white',
	fontWeight: font.fontWeightsix,
	width: '260px',
	fontFamily: font.fontFamily,
	borderRadius: '50px',
	height: '50px',
};

export const addbuttonStyle = {
	marginLeft: '5px',
	background: 'linear-gradient(135deg, #E935DD, #5887F5)',
	color: 'white',
	fontWeight: font.fontWeightfive,
	width: '100%',
	fontFamily: font.fontFamily,
	borderRadius: '150px',
	height: '35px',
	fontSize: font.fourteen,
};

export const TemplateGradientButton = {
	marginLeft: '5px',
	background: 'linear-gradient(135deg, #E935DD, #5887F5)',
	color: 'white',
	fontWeight: font.fontWeightsix,
	width: 'auto',
	fontFamily: font.fontFamily,
	borderRadius: '150px',
};
export const TemplateNormalButton = {
	marginLeft: '5px',
	background: '#8E98A7',
	color: 'white',
	fontWeight: font.fontWeightsix,
	width: 'auto',
	fontFamily: font.fontFamily,
	borderRadius: '150px',
};

export const validationError = {
	color: '#f35421',
};

export const ImportButtonStyle = {
	marginLeft: '5px',
	background: 'white',
	color: '#5887F5',
	border: '2px solid var(--blue, #5887F5)',
	fontWeight: font.fontWeightfive,
	fontFamily: font.fontFamily,
	borderRadius: '150px',
	height: '39px',
	// padding: '5px 13px 5px 8px',
};

export const DownloadExcelButtonStyle = {
	color: '#E935DD',
	fontWeight: font.fontWeightfive,
	fontFamily: font.fontFamily,
};
export const nextbuttonStyle = {
	background: 'linear-gradient(135deg, #E935DD, #5887F5)',
	color: 'white',
	fontWeight: font.fontWeightsix,
	width: '100px',
	fontFamily: font.fontFamily,
	borderRadius: '10px',
	height: '33px',
};

export const nextbuttonStyle1 = {
	background: 'linear-gradient(135deg, #E935DD, #5887F5)',
	color: 'white',
	fontWeight: font.fontWeightsix,
	width: '100px',
	fontFamily: font.fontFamily,
	borderRadius: '10px',
	height: '33px',
	marginLeft: '40px',
	marginTop: '10px',
};
export const cancelbuttonStyle = {
	marginLeft: '10px',
	background: 'black',
	color: 'white',
	fontWeight: font.fontWeightsix,
	width: '90%',
	fontFamily: font.fontFamily,
	borderRadius: '50px',
	height: '30px',
};

export const cartTitletextcommonStyle = {
	fontFamily: font.fontFamily,
	fontSize: font.sixteen,
	fontWeight: font.fontWeightsix,
	color: '#233145',
	marginTop: '1rem',
};

export const cartsliderTitletextcommonStyle = {
	fontFamily: font.fontFamily,
	fontSize: font.eighteen,
	fontWeight: font.fontWeightsix,
	color: '#323232',
};

export const cartDiscriptiontextcommonStyle = {
	fontFamily: font.fontFamily,
	fontSize: font.sixteen,
	fontWeight: font.fontWeightfive,
	color: '#8E98A7',
	paddingTop: '1rem',
};

export const cardProjectHeader = {
	fontFamily: font.fontFamily,
	fontSize: font.sixteen,
	fontWeight: font.fontWeightsix,
	color: '#323232',
};

export const cardProjectBody = {
	fontFamily: font.fontFamily,
	fontSize: font.sixteen,
	fontWeight: font.fontWeightfour,
	color: '#737374',
};

export const cartDiscriptionsmalltextcommonStyle = {
	fontFamily: font.fontFamily,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightsix,
	color: '#233145',
};

export const titleTextcommonStyle = {
	fontFamily: font.fontFamily,
	fontSize: font.twentyfour,
	fontWeight: font.fontWeightsix,
	color: '#233145',
	marginLeft: '2%',
	marginTop: '2%',
};

export const tableHeaderTextcommonStyle = {
	fontFamily: font.fontFamily,
	fontSize: font.ten,
	fontWeight: font.fontWeightThree,
	color: '#233145',
	marginLeft: '2%',
	marginTop: '2%',
	fontStyle: 'normal',
};

export const usertablesHeaderTextcommonStyle = {
	fontFamily: font.fontFamily,
	fontSize: font.eighteen,
	fontWeight: font.fontWeightsix,
	color: '#233145',
	fontStyle: 'normal',
};

export const uploadYourLogo = {
	fontFamily: font.fontFamily,
	fontSize: font.sixteen,
	fontWeight: font.fontWeightfive,
	color: '#8E98A7',
	fontStyle: 'normal',
};

export const footerStyles = {
	fontFamily: font.fontFamily,
	fontSize: font.sixteen,
	fontWeight: font.fontWeightfive,
	color: '#233145',
	fontStyle: 'normal',
	marginTop: '5px',
};

export const footerCopanyStyles = {
	fontFamily: font.fontFamily,
	fontSize: font.sixteen,
	fontWeight: font.fontWeightfive,
	color: '#233145',
	fontStyle: 'normal',
};
export const statusText = {
	fontFamily: font.fontFamily,
	fontSize: font.twelve,
	fontWeight: font.fontWeightfour,
	color: '#737374',
	fontStyle: font.fontStyleBold,
	padding: '2px',
};

export const tenderHeaderText = {
	fontFamily: font.fontFamily,
	fontSize: font.eighteen,
	fontWeight: font.fontWeightfive,
	color: colour.tenderHeader,
	fontStyle: font.fontStyleBold,
	marginLeft: '10px',
	marginTop: '4px',
};

export const tenderSubHeaderText = {
	fontFamily: font.fontFamily,
	fontSize: font.sixteen,
	fontWeight: font.fontWeightfive,
	color: colour.tenderSubHeader,
	fontStyle: font.fontStyleNormal,
};
export const projectSubHeaderText = {
	fontFamily: font.fontFamily,
	fontSize: font.sixteen,
	fontWeight: font.fontWeightfive,
	color: colour.tenderSubHeader,
	fontStyle: font.fontStyleNormal,
};

export const tenderbodyText = {
	fontFamily: font.fontFamily,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightfive,
	color: colour.tenderSubHeader,
	fontStyle: font.fontStyleNormal,
};

export const tenderbodyDaysText = {
	fontFamily: font.fontFamily,
	fontSize: font.twelve,
	fontWeight: font.fontWeightfive,
	color: colour.tenderDays,
	fontStyle: font.fontStyleNormal,
};

export const templatesubheaderText = {
	fontFamily: font.fontFamily,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightfive,
	color: colour.tenderNewBtn,
	fontStyle: font.fontStyleNormal,
	paddingTop: '3px',
};

export const templatesbodyText = {
	fontFamily: font.fontFamily,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightfour,
	color: colour.tenderSubHeader,
	fontStyle: font.fontStyleNormal,
};

export const tableHeader = {
	fontFamily: font.fontFamily,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightsix,
	color: colour.tableheader,
	fontStyle: font.fontStyleNormal,
};

export const usertableHeader = {
	fontFamily: font.fontFamily,
	fontSize: font.twenty,
	fontWeight: font.fontWeightSeven,
	color: colour.tableheader,
	fontStyle: font.fontStyleNormal,
};

export const tableProjectName: React.CSSProperties = {
	fontFamily: font.fontFamily,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightfour,
	color: colour.projectName,
	fontStyle: font.fontStyleNormal,
	textTransform: 'uppercase',
};

export const tableCommonStyle = {
	fontFamily: font.fontFamily,
	fontSize: font.sixteen,
	fontWeight: font.fontWeightfour,
	color: colour.tableCommonColour,
	fontStyle: font.fontStyleNormal,
};

export const tagButton = {
	display: 'flex',
	height: 'auto',
	alignItems: 'center',
	borderRadius: '8px',
	border: '1px solid #000',
	justifyContent: 'center',
	color: colour.tableCommonColour,
	fontFamily: font.fontFamily,
	fontSize: font.sixteen,
	fontWeight: font.fontWeightfour,
	fontStyle: font.fontStyleNormal,
	width: 'auto',
};

export const statusActiveButton = {
	display: 'flex',
	height: '40px',
	alignItems: 'center',
	borderRadius: '20px',
	border: '1px solid #46BCAA',
	justifyContent: 'center',
	backgroundColor: colour.active,
	color: colour.white,
	fontFamily: font.fontFamily,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightsix,
	fontStyle: font.fontStyleNormal,
};

export const statusCloseButton = {
	display: 'flex',
	height: '40px',
	alignItems: 'center',
	borderRadius: '20px',
	border: '1px solid #F35421',
	justifyContent: 'center',
	backgroundColor: colour.close,
	color: colour.white,
	fontFamily: font.fontFamily,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightsix,
	fontStyle: font.fontStyleNormal,
};

export const pdfText = {
	fontFamily: font.fontFamily,
	fontSize: font.sixteen,
	fontWeight: font.fontWeightsix,
	color: colour.pdfText,
	fontStyle: font.fontStyleNormal,
	marginLeft: '5px',
};

export const panelHeadertext = {
	fontFamily: font.fontFamily,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightsix,
	color: colour.pannelHeader,
	fontStyle: font.fontStyleNormal,
};

export const projectHeaders = {
	fontFamily: font.fontFamily,
	fontSize: font.sixteen,
	fontWeight: font.fontWeightsix,
	color: colour.pannelHeader,
	fontStyle: font.fontStyleNormal,
	marginLeft: '8px',
};

export const projectTitleHeaders = {
	fontFamily: font.fontFamily,
	fontSize: font.eighteen,
	fontWeight: font.fontWeightsix,
	color: colour.tenderNewBtn,
	fontStyle: font.fontStyleNormal,
};
export const projectSelectedBtn = {
	color: colour.pannelHeader,
	width: '250px',
	height: '50px',
	marginTop: '10px',
	fontSize: font.eighteen,
	fontWeight: font.fontWeightsix,
	justifyContent: 'center',
	alignContent: 'center',
	alignItems: 'center',
	fontFamily: font.fontFamily,
	bottom: '20px',
	backgroundColor: colour.selectTab,
};

export const schemaheadertext = {
	fontFamily: font.fontFamily,
	fontSize: font.eighteen,
	fontWeight: font.fontWeightsix,
	color: colour.tenderNewBtn,
	fontStyle: font.fontStyleNormal,
	display: 'flex',
	alignItems: 'center',
};

export const schemasubheadertext = {
	fontFamily: font.fontFamily,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightfour,
	color: colour.tenderNewBtn,
	fontStyle: font.fontStyleNormal,
};

export const schemaBox = {
	color: colour.pannelHeader,
	width: '200px',
	height: '50px',
	marginTop: '10px',
	fontSize: font.eighteen,
	fontWeight: font.fontWeightsix,
	justifyContent: 'flex-start',
	alignContent: 'center',
	alignItems: 'center',
	fontFamily: font.fontFamily,
	bottom: '20px',
	backgroundColor: colour.white,
	borderRadius: '20px',
	border: '1px solid #d3d3d3',
	display: 'flex',
	marginLeft: '20px',
};

export const Accordin = {
	backgroundColor: '#F5F5F5',
	display: 'flex',
	alignItems: 'center',
};

export const backBtn = {
	background: '#8E98A7',
	color: 'white',
	fontWeight: font.fontWeightsix,
	width: '100px',
	fontFamily: font.fontFamily,
	borderRadius: '10px',
	height: '33px',
};

export const addBiddertext = {
	color: colour.tenderNewBtn,
	fontSize: font.twentyfour,
	fontWeight: font.fontWeightfive,
	fontFamily: font.fontFamily,
};

export const createBiddertext = {
	color: colour.tableheader,
	fontSize: font.eighteen,
	fontWeight: font.fontWeightsix,
	fontFamily: font.fontFamily,
};

export const createBidderTitiletext = {
	color: colour.pannelHeader,
	fontSize: font.sixteen,
	fontWeight: font.fontWeightfive,
	fontFamily: font.fontFamily,
	marginTop: '10px',
	marginLeft: '10px',
};

export const createBidderSubTitiletext = {
	color: colour.pannelHeader,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightfour,
	fontFamily: font.fontFamily,
	marginLeft: '10px',
};

export const layoutBidder = {
	color: colour.white,
	width: '100%',
	height: '60px',
	fontFamily: font.fontFamily,
	backgroundColor: colour.white,
	borderRadius: '20px',
	border: '1px solid #8E98A7',
	display: 'flex',
	margin: '15px',
	paddingLeft: '1rem',
};

export const pdfBidder = {
	color: colour.white,
	width: '100%',
	height: '60px',
	fontFamily: font.fontFamily,
	backgroundColor: '#EDF8F7',
	borderRadius: '20px',
	border: '1px solid #46BCAA',
	justifyContent: 'center',
	alignContent: 'center',
	alignItems: 'center',
	marginTop: '1rem',
	paddingLeft: '5px',
};
export const pdfBiddersm = {
	color: colour.white,
	width: 'auto',
	height: '40px',
	fontFamily: font.fontFamily,
	backgroundColor: '#EDF8F7',
	borderRadius: '20px',
	border: '1px solid #46BCAA',
	justifyContent: 'center',
	alignContent: 'center',
	alignItems: 'center',
	marginTop: '1rem',
	marginLeft: '2rem',
	paddingRight: '1rem',
	paddingLeft: '1rem',
};

export const addBitterBtn = {
	backgroundColor: colour.darkBtn,
	borderRadius: '10px',
	color: colour.white,
	width: '100%',
	height: '46px',
	marginTop: '5px',
	fontSize: font.fourteen,
	fontStyle: font.fontStyleNormal,
	fontFamily: font.fontFamily,
	alignContent: 'center',
	alignItems: 'center',
	justifyContent: 'center',
	'&:hover': {
		backgroundColor: 'black',
		color: 'white',
	},
};
export const addBitterText = {
	color: colour.white,
	fontSize: font.fourteen,
	fontStyle: font.fontStyleNormal,
	fontFamily: font.fontFamily,
};

export const selectedBitter = {
	background: 'white',
	borderImageSlice: 1,
	borderImageSource: 'linear-gradient(to left, #743ad5, #d53a9d)',
	width: '250px',
	height: '50px',
	justifyContent: 'center',
	alignContent: 'center',
	alignItems: 'center',
	bottom: '20px',
	borderRadius: '2px',
	fontSize: font.eighteen,
	fontWeight: 'bold',
	backgroundImage: 'linear-gradient(to left, #743ad5, #d53a9d)',
	WebkitBackgroundClip: 'text',
	WebkitTextFillColor: 'transparent',
	padding: '5px 10px',
};

export const dialogYesBox = {
	backgroundColor: '#F35C2C',
	width: '150px',
	height: '50px',
	justifyContent: 'center',
	alignContent: 'center',
	alignItems: 'center',
	bottom: '20px',
	fontSize: font.eighteen,
	fontWeight: 'bold',
	color: '#fff',
	borderRadius: '10px',
	marginLeft: '20px',
};

export const dialogNoBox = {
	backgroundColor: '#FDEDE8',
	width: '150px',
	height: '50px',
	justifyContent: 'center',
	alignContent: 'center',
	alignItems: 'center',
	bottom: '20px',
	fontSize: font.eighteen,
	fontWeight: 'bold',
	color: '#F35C2C',
	borderRadius: '10px',
};

export const NoBox = {
	width: '12rem',
	borderRadius: '17px',
	padding: '10px',
	color: '#F35C2C',
	backgroundColor: '#FDEDE8',
};

export const YesBox = {
	width: '12rem',
	borderRadius: '17px',
	padding: '10px',
	backgroundColor: '#F35C2C',
};

export const CommonPageHeader = {
	fontFamily: font.fontFamily,
	fontSize: font.twenty,
	fontWeight: font.fontWeightfive,
	color: 'rgb(35, 49, 69)',
};

export const dialogHeader = {
	color: '#F35C2C',
	fontSize: font.eighteen,
	fontWeight: font.fontWeightsix,
	fontFamily: font.fontFamily,
};

export const projectHeader = {
	fontFamily: font.fontFamily,
	fontSize: font.eighteen,
	fontWeight: font.fontWeightEight,
	color: colour.tableheader,
	fontStyle: font.fontStyleNormal,
	marginTop: '5px',
};

export const projectBody = {
	fontFamily: font.fontFamily,
	fontSize: font.eighteen,
	fontWeight: font.fontWeightEight,
	color: colour.tableheader,
	fontStyle: font.fontStyleNormal,
	marginTop: '5px',
};

export const templateCardDropdown = {
	display: 'inline-flex',
	padding: '20px',
	alignItems: 'flex-start',
	gap: '12px',
	height: 'auto',
	width: '500px',
	backgroundColor: '#fff',
};

export const schemaBg = {
	background: '#fff',
	borderRadius: '10px',
	borderColor: '#8E98A7',
	borderStyle: 'solid',
	borderWidth: '1px',
	paddingRight: '10px',
	width: 'auto',
};

export const treeschemaBg = {
	background: '#8E98A7',
	borderRadius: '10px',
	borderColor: '#8E98A7',
	borderStyle: 'solid',
	borderWidth: '1px',
	paddingRight: '10px',
	width: '200px',
	color: '#fff',
};

export const templateHeader = {
	fontFamily: font.fontFamily,
	fontSize: font.sixteen,
	fontWeight: font.fontWeightsix,
	color: colour.tenderNewBtn,
	fontStyle: font.fontStyleNormal,
};

export const templateBody = {
	fontFamily: font.fontFamily,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightfour,
	color: colour.tenderNewBtn,
	fontStyle: font.fontStyleNormal,
};

export const accessTypeHeader = {
	fontFamily: font.fontFamily,
	fontSize: font.eighteen,
	fontWeight: font.fontWeightfive,
	color: colour.tenderNewBtn,
	fontStyle: font.fontStyleBold,
};

export const accessTypeBody = {
	fontFamily: font.fontFamily,
	fontSize: font.sixteen,
	fontWeight: font.fontWeightfive,
	color: colour.tenderNewBtn,
	fontStyle: font.fontStyleNormal,
};

export const accessTypeCardSpan = {
	fontFamily: font.fontFamily,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightfive,
	color: colour.tenderSubHeader,
	fontStyle: font.fontStyleNormal,
};
export const accessTypeCardHeader = {
	fontFamily: 'Roboto',
	fontSize: '16px',
	fontWeight: '500',
	color: '#323232',
	// fontStyle: font.fontStyleNormal,
};
export const accessTypeCardSpanLight = {
	fontFamily: font.fontFamily,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightfour,
	color: colour.tenderSubHeader,
	fontStyle: font.fontStyleNormal,
};

export const sliderHearedText = {
	fontFamily: 'Roboto',
	fontSize: '14px',
	fontWeight: '600',
	color: '#8E98A7',
	fontStyle: 'normal',
};

export const sliderBodyText = {
	fontFamily: font.fontFamily,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightfive,
	color: colour.tenderNewBtn,
	fontStyle: font.fontStyleNormal,
	height: '50px',
};

export const sliderBodyText2 = {
	fontFamily: font.fontFamily,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightfour,
	color: colour.tenderNewBtn,
	fontStyle: font.fontStyleNormal,
	height: '55px',
};

export const offCanvasBodyHeader = {
	fontFamily: font.fontFamily,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightsix,
	color: colour.tenderNewBtn,
	fontStyle: font.fontStyleNormal,
};

export const ProfileName = {
	fontFamily: font.fontFamily,
	fontSize: font.fourteen,
	fontWeight: font.fontWeightfive,
	color: colour.tenderNewBtn,
	fontStyle: font.fontStyleNormal,
};

export const EditPanelDeleteButton = {
	border: '1px solid #5887F5',
	color: '#5887F5',
	marginTop: '1rem',
};

export const GreyButton = {
	fontWeight: font.fontWeightfive,
	background: '#8E98A7',
	color: '#FEFEFF',
	fontFamily: font.fontFamily,
	fontSize: '14px',
};

export const saveDraftbuttonStyle = {
	background: 'linear-gradient(135deg, #E935DD, #5887F5)',
	color: 'white',
	fontWeight: font.fontWeightsix,
	fontFamily: font.fontFamily,
	borderRadius: '20px',
};


export const LoginHeader = {

	fontSize:'13px',
	fontFamily:font.fontFamily,
	fontWeight: font.fontWeightfive,
	color:'black'

}

export const VerificationCodeTimerSpan={
	color:'#5887F5',
	fontWeight: font.fontWeightfive,

}
