import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Logo from '../../assets/loginPageAssets/TenderAILogo.svg';
import { Box, styled, Typography } from '@mui/material';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import {
	sliderBodyText,
	sliderHearedText,
	StyledStar,
	validationError,
} from '../../commonstyle/styles';
import { Checkbox } from 'primereact/checkbox';
import GoogleIcon from '../../assets/loginPageAssets/GoogleSignIn.svg';
import AppleIcon from '../../assets/loginPageAssets/AppleIcon.svg';
import MicrosfotIcon from '../../assets/loginPageAssets/MircrosoftIcon.svg';
import axios from 'axios';
import ToastComponent from '../components/toast/toast';
import UserManagementServices from '../../services/UserManagementServices';
import AuthServices from '../../services/AuthServices';
import { fetchPolicyForEmailSuccess } from '../../redux/Action/abacPolicyAction';
// import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';

const CustomButton = styled('button')({
	background: 'white',
	color: '#5887F5',
	border: '0px',
	float: 'right',
	marginLeft: '13rem',
	marginTop: '0rem',
	fontFamily: 'Roboto',
	fontSize: '14px',
	fontWeight: '500',
});
const SignUpButton = styled('button')({
	background: 'white',
	color: '#5887F5',
	border: '0px',
	fontFamily: 'Roboto',
	fontSize: '14px',
	fontWeight: '500',
});

const LoginButton = styled('button')({
	width: '32rem',
	height: '3rem',
	background: '#5887F5',
	color: 'white',
	border: '0px',
	marginTop: '1rem',
	borderRadius: '27px',
});
const ContinueWithGoogleButton = styled('button')({
	width: '20rem',
	height: '3.3rem',
	background: 'white',
	color: '#8E98A7',
	border: '1px  solid grey',
	marginTop: '1rem',
	borderRadius: '20px',
});

const SmallButtons = styled('button')({
	width: '5rem',
	height: '3.3rem',
	background: 'white',
	color: '#8E98A7',
	border: '1px  solid grey',
	marginTop: '1rem',
	borderRadius: '20px',
});

function LoginNew({}: any) {
	const [checked, setChecked] = useState<any>(false);
	const [username, setUsername] = useState<string>(localStorage.getItem('email') || '');
	const [password, setPassword] = useState<string>(localStorage.getItem('password') || '');
	const [showToast, setShowToast] = useState(false);
	const [toastSeverity, setToastSeverity] = useState<'success' | 'error' | undefined>(undefined);
	const [toastMessage, setToastMessage] = useState<string>('');
	const [isLoginLoading, setisLoginLoading] = useState<boolean>(false);
	const [abacPolicy, setAbacPolicy] = useState<any>([]);
	const [isEmailRequired, setEmailRequired] = useState<boolean>(false);
	const [isPasswordrequired, setPasswordRequired] = useState<boolean>(false);
	const [passwordVisible, setPasswordVisible] = useState(false);

	const togglePasswordVisibility = () => {
		setPasswordVisible(!passwordVisible);
	};

	const dispatch = useDispatch();

	const handleChangeUserName = (e: any) => {
		setUsername(e.target.value);
		setEmailRequired(false);
	};
	const handleChangePassword = (e: any) => {
		setPassword(e.target.value);
		setPasswordRequired(false);
	};

	const baseURL = process.env.REACT_APP_API_URL_DEV;

	const navigate = useNavigate();

	const handleLogin = async (event: any) => {
		const payload = new URLSearchParams();
		payload.append('username', username);
		payload.append('password', password);
		setShowToast(false);
		if (username === '' || password === '') {
			setEmailRequired(username === '');
			setPasswordRequired(password === '');
		} else {
			setisLoginLoading(true);
			localStorage.setItem('email', username);
			localStorage.setItem('password', password);
			try {
				const response = await axios.post(baseURL + '/token/', payload, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				});
				if (response?.data?.access_token) {
					setisLoginLoading(false);
					localStorage.setItem('access_token', response?.data?.access_token);
					const accessControl = await AuthServices.abacAccess(username);
					setAbacPolicy(accessControl);
					localStorage.setItem('abacAccess', JSON.stringify(accessControl));
					navigate('/dashboard');
				} else {
					setEmailRequired(true);
					setPasswordRequired(true);
					setisLoginLoading(false);
					// navigate('/tools');
					setShowToast(true);
					setToastSeverity('error');
					setToastMessage(response?.data?.message);
				}
			} catch (error) {
				setEmailRequired(true);
				setisLoginLoading(false);
				setPasswordRequired(true);
				// navigate('/tools');
				setShowToast(true);
				setToastSeverity('error');
				setToastMessage(
					password !== '' || username !== ''
						? 'Enter Valid Email and Password'
						: 'Enter Valid Email and Password',
				);
			}
		}
	};

	const handleForgotPassword = () => {
		navigate('/forgotPassword');
	};

	const handleCheckboxChange = (e: any) => {
		setChecked(e.target.checked);
		if (e.target.checked) {
			localStorage.setItem('email', username);
			localStorage.setItem('password', password);
		}
	};

	useEffect(() => {
		const email = localStorage.getItem('email');
		const password = localStorage.getItem('password');
		if (email && password) {
		  setChecked(true);
		} else {
			localStorage.setItem('email', username);
			localStorage.setItem('password', '');
		  setChecked(false);
		}
	  }, []); 
	useEffect(() => {
		teamsName();
	}, []);

	useEffect(() => {
		const handlePopState = (event: PopStateEvent) => {
		  navigate('/'); 
		};
		window.addEventListener('popstate', handlePopState);
		return () => {
		  window.removeEventListener('popstate', handlePopState);
		};
	  }, [navigate]);

	useEffect(() => {
		localStorage.setItem('facit_authUsername', 'john');
		localStorage.setItem('activeTab', `User`);
		localStorage.setItem('foldersName', '');
		localStorage.setItem('projectID', '');
		localStorage.setItem('projectName', '');
		localStorage.setItem('folderName', '');
		localStorage.setItem('abacAccess', '');
		localStorage.setItem('hasReloaded', 'false');
		// localStorage.setItem('email', '');
		// localStorage.setItem('password', '');
		// localStorage.setItem('facit_darkModeStatus', false);
	}, []);

	const teamsName = async () => {
		try {
			const response = await UserManagementServices.getReNameTeamTabs();
			if (response) {
				if (response[0]?._id) {
					localStorage.setItem('teamName', response[0]?.name);
					localStorage.setItem('teamId', response[0]?._id);
				}
			}
		} catch (error) {
			throw error;
		}
	};

	return (
		//    <Box sx={{display:'flex',justifyContent:'center'}}>
		<Box
			sx={{
				maxWidth: 'fit-content',
				marginLeft: 'auto',
				marginRight: 'auto',
				paddingTop: '10rem',
			}}>
			{showToast && (
				<ToastComponent
					severity={toastSeverity}
					label={toastSeverity === 'error' ? 'Error' : 'Success'}
					message={toastMessage}
					show={showToast}
				/>
			)}
			<Card style={{ width: '35rem' }}>
				<CardBody>
					<Box sx={{ maxWidth: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}>
						<img src={Logo} alt='' />
					</Box>
					<Box sx={{ marginTop: '3rem' }}>
						<Typography>Log In</Typography>
						<div className='row g-3 my-2'>
							<Box sx={{ height: 10 }}>
								<Typography style={sliderHearedText}>
									Email <StyledStar>*</StyledStar>
								</Typography>
							</Box>
							<div className='col-12'>
								<FormGroup id='email' label=''>
									<Input
										name='email'
										placeholder=''
										onChange={handleChangeUserName}
										// onBlur={userformik.handleBlur}
										value={username}
										// isValid={userformik?.isValid}
										// invalidFeedback={userformik?.errors?.full_name}
										// isTouched={userformik?.touched?.full_name}
										// validFeedback='Looks good!'
										style={{
											...sliderBodyText,
											borderWidth: '1px',
											borderStyle: 'solid',
											borderColor: isEmailRequired
												? validationError?.color
												: 'transparent',
										}}
									/>
									<>
											{isEmailRequired ? (
												<Typography
													variant='caption'
													style={validationError}>
												Please	Enter Valid Email
												</Typography>
											) : null}
										</>
								</FormGroup>
							</div>
							<Box sx={{ height: 10 }}>
								<Typography style={sliderHearedText}>
									Password <StyledStar>*</StyledStar>
								</Typography>
							</Box>
							<div className='col-12'>
								<FormGroup id='password' label=''>
									<div style={{ position: 'relative' }}>
										<Input
											type={passwordVisible ? 'text' : 'password'} // Toggle input type
											name='password'
											placeholder=''
											onChange={handleChangePassword}
											value={password}
											style={{
												...sliderBodyText,
												borderWidth: '1px',
												borderStyle: 'solid',
												borderColor: isPasswordrequired
													? validationError?.color
													: 'transparent',
												paddingRight: '2.5rem', // Add padding to make space for the icon
											}}
										/>
										<span
											onClick={togglePasswordVisibility}
											style={{
												position: 'absolute',
												right: '10px',
												top: '50%',
												transform: 'translateY(-50%)',
												cursor: 'pointer',
											}}>
											{passwordVisible ? <FaEyeSlash /> : <FaEye />}{' '}
											{/* Toggle icon */}
										</span>
									</div>
									<>
											{isPasswordrequired ? (
												<Typography
													variant='caption'
													style={validationError}>
												Please	Enter Valid Password
												</Typography>
											) : null}
										</>
								</FormGroup>
								<Box sx={{ display: 'flex', gap: '5px', marginTop: '1rem' }}>
									<Box>
										<Checkbox
											onChange={handleCheckboxChange}
											checked={checked}></Checkbox>
									</Box>
									<Box>
										<Typography style={sliderHearedText}>
											Remember me
										</Typography>
									</Box>
									<CustomButton onClick={handleForgotPassword}>
										Forgot Password
									</CustomButton>
								</Box>
							</div>
						</div>
						<Box>
							<LoginButton onClick={handleLogin}>
								{' '}
								{isLoginLoading ? (
									<>
										<span
											className='spinner-grow spinner-grow-sm'
											role='status'
											aria-hidden='true'></span>
										Login...
									</>
								) : (
									'Login'
								)}
							</LoginButton>
						</Box>
						{/* 
						<Box sx={{ display: 'flex', gap: '1rem' }}>
							<Box>
								<ContinueWithGoogleButton>
									{' '}
									<img src={GoogleIcon} alt='' />
									Continue with Google
								</ContinueWithGoogleButton>
							</Box>
							<Box>
								<SmallButtons>
									<img src={AppleIcon} alt='' />
								</SmallButtons>
							</Box>
							<Box>
								<SmallButtons>
									<img src={MicrosfotIcon} alt='' />
								</SmallButtons>
							</Box>
						</Box>

						<Box sx={{ marginTop: '1rem', marginLeft: '10rem' }}>
							<Typography>
								No account?
								<SignUpButton>Sign Up</SignUpButton>
							</Typography>
						</Box> */}
					</Box>
				</CardBody>
			</Card>
		</Box>
	);
}

export default LoginNew;
