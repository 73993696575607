import React, { SVGProps } from 'react';

const BackArrow = (props: SVGProps<SVGSVGElement>) => (


    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" className='svg-icon'
        {...props}>
        <path d="M8.5 16C6.26667 16 4.375 15.225 2.825 13.675C1.275 12.125 0.5 10.2333 0.5 8C0.5 5.76667 1.275 3.875 2.825 2.325C4.375 0.775 6.26667 0 8.5 0C9.65 0 10.75 0.237333 11.8 0.712C12.2308 0.906759 12.6364 1.13597 13.0167 1.39964C13.5816 1.79127 14.5 1.42574 14.5 0.738376C14.5 0.330582 14.8306 0 15.2384 0H15.5C16.0523 0 16.5 0.447715 16.5 1V3.5C16.5 5.433 14.933 7 13 7H10.5C9.94771 7 9.5 6.55228 9.5 6C9.5 5.44772 9.94771 5 10.5 5H11.0423C12.2265 5 12.8969 3.72701 11.9206 3.05673C11.7892 2.96654 11.6534 2.88097 11.513 2.8C10.5883 2.26667 9.584 2 8.5 2C6.83333 2 5.41667 2.58333 4.25 3.75C3.08333 4.91667 2.5 6.33333 2.5 8C2.5 9.66667 3.08333 11.0833 4.25 12.25C5.41667 13.4167 6.83333 14 8.5 14C9.78333 14 10.9417 13.6333 11.975 12.9C12.8034 12.3121 13.4337 11.5742 13.8657 10.6864C14.0605 10.2863 14.4483 10 14.8933 10C15.5788 10 16.0808 10.6528 15.8026 11.2794C15.2713 12.4758 14.4704 13.4911 13.4 14.325C11.9667 15.4417 10.3333 16 8.5 16Z" fill="#8E98A7" />
    </svg>
);

export default BackArrow;
