import React, { SVGProps } from 'react';
const SvgCustomApple = (props: SVGProps<SVGSVGElement>) => (
<svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 15.5H14.5V17.5H0.5V15.5ZM0.5 7.5H4.5V13.5H10.5V7.5H14.5L7.5 0.5L0.5 7.5ZM8.5 5.5V11.5H6.5V5.5H5.33L7.5 3.33L9.67 5.5H8.5Z" fill="#8E98A7"/>
</svg>

    

);

export default SvgCustomApple;
