import React, { SVGProps } from 'react';

const SvgCustomTender = (props: SVGProps<SVGSVGElement>) => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clip-path="url(#clip0_6014_11964)">
<path opacity="0.3" d="M4 8H20V18H4V8Z" fill="#8E98A7"/>
<path d="M20 6H12L10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6ZM20 18H4V8H20V18Z" fill="#8E98A7"/>
</g>
<defs>
<clipPath id="clip0_6014_11964">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>


);

export default SvgCustomTender;
