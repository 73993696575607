import React, { SVGProps } from 'react';

const SvgCustomNodeTree = (props: SVGProps<SVGSVGElement>) => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clip-path="url(#clip0_6103_15667)">
<path d="M20 5V4C20 3.45 19.55 3 19 3H17C16.45 3 16 3.45 16 4V5H15V9C15 9.55 15.45 10 16 10H17V17C17 18.1 16.1 19 15 19C13.9 19 13 18.1 13 17V7C13 4.79 11.21 3 9 3C6.79 3 5 4.79 5 7V14H4C3.45 14 3 14.45 3 15V19H4V20C4 20.55 4.45 21 5 21H7C7.55 21 8 20.55 8 20V19H9V15C9 14.45 8.55 14 8 14H7V7C7 5.9 7.9 5 9 5C10.1 5 11 5.9 11 7V17C11 19.21 12.79 21 15 21C17.21 21 19 19.21 19 17V10H20C20.55 10 21 9.55 21 9V5H20Z" fill="#8E98A7"/>
</g>
<defs>
<clipPath id="clip0_6103_15667">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>


);

export default SvgCustomNodeTree;
