

import React, { SVGProps } from 'react';
const SvgCustomApple = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <g clip-path="url(#clip0_2758_28845)">
      <path opacity="0.3" d="M19 5H5V19H19V5ZM9 17H7V10H9V17ZM13 17H11V7H13V17ZM17 17H15V13H17V17Z" fill="url(#paint0_linear_2758_28845)"/>
      <path d="M3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3H5C3.9 3 3 3.9 3 5ZM5 5H19V19H5V5ZM7 10H9V17H7V10ZM11 7H13V17H11V7ZM15 13H17V17H15V13Z" fill="url(#paint1_linear_2758_28845)"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_2758_28845" x1="12" y1="5" x2="12" y2="19" gradientUnits="userSpaceOnUse">
        <stop stop-color="#E935DD"/>
        <stop offset="1" stop-color="#5887F5"/>
      </linearGradient>
      <linearGradient id="paint1_linear_2758_28845" x1="12" y1="3" x2="12" y2="21" gradientUnits="userSpaceOnUse">
        <stop stop-color="#E935DD"/>
        <stop offset="1" stop-color="#5887F5"/>
      </linearGradient>
      <clipPath id="clip0_2758_28845">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
  

);

export default SvgCustomApple;
