import React, { SVGProps } from 'react';
const SvgCustomApple = (props: SVGProps<SVGSVGElement>) => (
    <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg" >
    <path id="Vector" d="M14.81 7.86653L11.06 4.11653L2 13.1765V16.9265H5.75L14.81 7.86653ZM4 14.9265V14.0065L11.06 6.94653L11.98 7.86653L4.92 14.9265H4ZM17.71 4.96653C17.8027 4.87402 17.8762 4.76413 17.9264 4.64316C17.9766 4.52218 18.0024 4.3925 18.0024 4.26153C18.0024 4.13056 17.9766 4.00088 17.9264 3.87991C17.8762 3.75893 17.8027 3.64904 17.71 3.55653L15.37 1.21653C15.1825 1.03052 14.9291 0.926147 14.665 0.926147C14.4009 0.926147 14.1475 1.03052 13.96 1.21653L12.13 3.04653L15.88 6.79653L17.71 4.96653ZM0 18.9265H20V22.9265H0V18.9265Z" fill="#8E98A7"/>
    </svg>
    

);

export default SvgCustomApple;
