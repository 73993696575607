import React, { SVGProps } from 'react';

const SvgLayers = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg  {...props}  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.0134 2.91995L18.9134 5.53995C20.6134 6.28995 20.6134 7.52995 18.9134 8.27995L13.0134 10.8999C12.3434 11.1999 11.2434 11.1999 10.5734 10.8999L4.67344 8.27995C2.97344 7.52995 2.97344 6.28995 4.67344 5.53995L10.5734 2.91995C11.2434 2.61995 12.3434 2.61995 13.0134 2.91995Z" stroke="#8E98A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 11C3 11.84 3.63 12.81 4.4 13.15L11.19 16.17C11.71 16.4 12.3 16.4 12.81 16.17L19.6 13.15C20.37 12.81 21 11.84 21 11" stroke="#8E98A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 16C3 16.93 3.55 17.77 4.4 18.15L11.19 21.17C11.71 21.4 12.3 21.4 12.81 21.17L19.6 18.15C20.45 17.77 21 16.93 21 16" stroke="#8E98A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

	);
};

export default SvgLayers;
