import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
 
interface Errors {
  email?: string;
}
export const decodeBase64Image = (base64String: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        try {
            // Check if the base64 string is valid
            if (!isValidBase64(base64String)) {
                reject(new Error('Invalid base64 string'));
                return;
            }
 
            const binaryString = atob(base64String);
            const blob = new Blob([binaryString], { type: 'image/jpeg' });
            const imageUrl = URL.createObjectURL(blob);
            resolve(imageUrl);
 
            // Define and return a cleanup function to revoke the object URL
            const cleanup = () => URL.revokeObjectURL(imageUrl);
            return cleanup;
        } catch (error) {
            reject(error);
        }
    });
};
 
const isValidBase64 = (str: string): boolean => {
    try {
        return btoa(atob(str)) === str;
    } catch (error) {
        return false;
    }
};
 
// axiosUtils.js
 
 
// Function to handle Axios errors
export const handleAxiosError = (error:any) => {
  if (error.response) {
    console.error('Response Error:', error.response.data);
    console.error('Response Status:', error.response.status);
    console.error('Response Headers:', error.response.headers);
    return error.response.data; // Return the error response data
  } else if (error.request) {
   
    console.error('Request Error:', error.request);
    return 'Request error';
  } else {
   
    console.error('Error:', error.message);
    return 'Unknown error';
  }
};
 
export const axiosRequest = async (config:any) => {
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return handleAxiosError(error);
  }
};
 
 
 
 
export const formatDate = (dateStr: any): string => {
  const date = new Date(dateStr);
  const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  return formattedDate;
};
 
export const capitalizeFirstLetter = (str:any) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};
 
export function getFormattedStartDate(): any {
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedDay = day < 10 ? `0${day}` : `${day}`;
 
  return `${year}-${formattedMonth}-${formattedDay}`;
}
 
 
export const convertToLowerCase = (input: string): string => {
  return input.toLowerCase();
};
 
export function validateEmail(email: string): Errors {
  let errors: Errors = {};
  if (!email) {
      errors.email = 'Email is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errors.email = 'Invalid email address';
  }
  return errors;
}
 
export function generateUUID() {
  return uuidv4();
}
 
// Function to validate if a given string is a valid UUID
export function isValidUUID(uuid:any) {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
}
 
 
 
// validationUtils.ts
 
const minLength = 3;
const maxLength = 50;
 
export const validateTemplateName = (templateName: string): boolean => {
  return !templateName || templateName.length < minLength || templateName.length > maxLength;
};
 
export const validateTemplateDescription = (templateDescription: string): boolean => {
  return !templateDescription || templateDescription.length < minLength || templateDescription.length > maxLength;
};
 
export const validateTemplateProjectType = (templateProjectType: string): boolean => {
  return !templateProjectType; // Add any additional validation logic if needed
};
 
export const validateTemplateProjectArea = (templateProjectArea: string): boolean => {
  return !templateProjectArea; // Add any additional validation logic if needed
};
 
export const validateTemplateSchema = (templateSchema: string): boolean => {
  return !templateSchema; // Add any additional validation logic if needed
};
 
// Utility function to validate all fields
export const validateInputs = (
  templateName: string,
  templateDescription: string,
  templateProjectType: string,
  templateProjectArea: string,
  templateSchema: string
): {
  templateNameRequired: boolean;
  templateDescriptionRequired: boolean;
  templateProjectTypeRequired: boolean;
  templateProjectAreaRequired: boolean;
  templateSchemaRequired: boolean;
} => {
  return {
    templateNameRequired: validateTemplateName(templateName),
    templateDescriptionRequired: validateTemplateDescription(templateDescription),
    templateProjectTypeRequired: validateTemplateProjectType(templateProjectType),
    templateProjectAreaRequired: validateTemplateProjectArea(templateProjectArea),
    templateSchemaRequired: validateTemplateSchema(templateSchema),
  };
};
 
 
 
/**
 * Formats a given date into "9 July 2024" format.
 * @param {Date | string | number} date - The date to format.
 * @returns {string} The formatted date string.
 */
export function formatDateToCustomFormat(date: Date | string | number): string {
  const dateObj = new Date(date);
  if (isNaN(dateObj.getTime())) {
      throw new Error('Invalid date');
  }
  const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
  };
  const formatter = new Intl.DateTimeFormat('en-GB', options);
  return formatter.format(dateObj);
}


export const isValidEmail = (email: string): boolean => {
  console.log("email===>",email)
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex?.test(email);
};