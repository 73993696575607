import React, { useState,useEffect } from 'react';
import LoginNew from './LoginNew';
import ForgotPassword from './ForgotPassword';
import CreatePassword from './CreatePassword';
import UpdatePassword from './UpdatePassword';
import VerificationCode from './VerificationCode';
import ResetPassword from './ResetPassword';
import UserManagementServices from '../../services/UserManagementServices';
import axios from 'axios';
// import { usePassword } from './PasswordContext';

function LoginHome() {
	const [forgotPassword, setForgotPassword] = useState<boolean>(false);
	const [createPassword, setCreatePassword] = useState<boolean>(false);
	const [updatePassword, setUpdatePassword] = useState<boolean>(false);
	const [verificationCode, setVerificationCode] = useState<boolean>(false);
	const [loginIsVisible, setLoginIsVisible] = useState<boolean>(true);
	const [resetPassword, setResetPassword] = useState<boolean>(false);
	const [showToast, setShowToast] = useState(false);
	const [toastSeverity, setToastSeverity] = useState<'success' | 'error' | undefined>(undefined);
	const [toastMessage, setToastMessage] = useState<string>('');
	const [isLoginLoading, setisLoginLoading] = useState<boolean>(false);

	const queryString = window.location.search;
	const params = new URLSearchParams(queryString);
	const newUserId = params.get('uid');

	const baseURL = process.env.REACT_APP_API_URL_DEV;

	// const GetabacPolicy= async()=>{
	// 	const policyResponse = await axios.get(baseURL + `/policies/${email}`)
	// }
	


	useEffect(() => {
		if (newUserId) {
			fetchUserDetails();
		}
	}, []);

	const fetchUserDetails = async (): Promise<void> => {
		const userId = newUserId || '';
		try {
			const response = await UserManagementServices.getEmail(userId);
			if (response?.error) {
				setShowToast(true);
				setToastSeverity('error');
				setToastMessage(response?.message);
			} else {
				localStorage.setItem('email', response?.email);
				setCreatePassword(true);
				setLoginIsVisible(false);
				setForgotPassword(false);
			}
		} catch (error) {
			setShowToast(true);
			setToastSeverity('error');
			setToastMessage('An unexpected error occurred.');
		}
	};


	return (
		<>
			{loginIsVisible && (
				<LoginNew
					setForgotPassword={setForgotPassword}
					setLoginIsVisible={setLoginIsVisible}
					setCreatePassword={setCreatePassword}
				/>
			)}

			{/* {forgotPassword && <ForgotPassword 	setForgotPassword={setForgotPassword}   setVerificationCode={setVerificationCode} setLoginIsVisible={setLoginIsVisible}/>} */}
			{createPassword && <CreatePassword setLoginIsVisible={setLoginIsVisible} setCreatePassword={setCreatePassword} />}
			{resetPassword && <ResetPassword setLoginIsVisible={setLoginIsVisible}  setResetPassword={setResetPassword}/>}

			{updatePassword && <UpdatePassword 	setLoginIsVisible={setLoginIsVisible} setUpdatePassword={setUpdatePassword}/>}
			 
			{/* {verificationCode && <VerificationCode setResetPassword={setResetPassword} setVerificationCode={setVerificationCode} setForgotPassword={setForgotPassword}/>} */}
		</>
	);
}

export default LoginHome;
