import data from '../common/data/dummyProductData';
import { userManagmentFomData, userManagmentServer } from '../http-common';

const getUsers = async () => {
	try {
		const response = await userManagmentServer.get('/users');
		return response.data;
	} catch (error) {
		throw error;
	}
};

const getUserId = async (id:string) => {
	try {
		const response = await userManagmentServer.get('/users/'+ id );
		return response.data;
	} catch (error) {
		throw error;
	}
};

const getSystemRoles = async () => {
	try {
		const response = await userManagmentServer.get('/systemroles');
		return response.data;
	} catch (error) {
		throw error;
	}
};

const getEmail = async(id:any) => {
	try {
	  const response = await userManagmentServer.get(`/users/${id}/email`);
	  return response.data;
	} catch (error) {
		throw error;
	}
  };
  
const createUser = (data: any) => {
	try {
		return userManagmentFomData.post('/users/', data);
	} catch (error) {
		throw error;
	}
};

const createRoles = (data: any) => {
	try {
		return userManagmentFomData.post('/roles/', data);
		
	} catch (error) {
		throw error;
	}
};

const createTeams = (data: any) => {
	try {
		return userManagmentFomData.post('/teams/', data);
	} catch (error) {
		throw error;
	}
};

const getTeams = async () => {
	try {
		const response = await userManagmentServer.get('/teams');
		return response.data;
	} catch (error) {
		throw error;
	}
};

const getRoles = async () => {
	try {
		const response = await userManagmentServer.get('/roles');
		return response.data;
	} catch (error) {
		throw error;
	}
};

const getReNameTeamTabs = async () => {
	try {
		const response = await userManagmentServer.get('/teams/sections/');
		return response.data;
	} catch (error) {
		throw error;	
	}
};

const reNameTeamTabs = async (id:any,data:any) => {
	try {
		const response = await userManagmentServer.put('/teams/section/'+id,data);
		return response.data;
	} catch (error) {
		throw error;
	}
};

const updateUserDetails = async (id:any,data:any) => {
	try {
		const response = await userManagmentFomData.put('/users/'+id,data);
		return response.data;
	} catch (error) {
		throw error;
	}
};


const UserManagementServices = {
	getUsers,
	createUser,
	createRoles,
	createTeams,
	getTeams,
	getRoles,
	getUserId,
	updateUserDetails,
	reNameTeamTabs,
	getReNameTeamTabs,
	getSystemRoles,
	getEmail
};

export default UserManagementServices;
