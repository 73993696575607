import { Box, styled, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Card, { CardBody, CardFooter } from '../../components/bootstrap/Card';
import Logo from '../../assets/loginPageAssets/TenderAILogo.svg';
import {
	LoginHeader,
	sliderBodyText,
	sliderHearedText,
	StyledStar,
	validationError,
} from '../../commonstyle/styles';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import AuthServices from '../../services/AuthServices';
import ToastComponent from '../components/toast/toast';
import { isValidEmail } from '../../utils/utils';
import { set } from 'date-fns';
import { useNavigate } from 'react-router-dom';

const SubmitButton = styled('button')({
	width: '32rem',
	height: '42px',
	background: 'linear-gradient(135deg, rgb(233, 53, 221), rgb(88, 135, 245))',
	color: 'white',
	border: '0px',
	marginTop: '1rem',
	borderRadius: '13px',
});

const LoginButton = styled('button')({
	width: '14rem',
	height: '40px',
	background: '#000000',
	color: 'white',
	border: '0px',
	marginTop: '1rem',
	borderRadius: '15px',
});

interface ToastState {
	showToast: boolean;
	toastSeverity: 'success' | 'error';
	toastMessage: string;
}
function ForgotPassword({  }: any) {
	const [email, setEmail] = useState<string>(localStorage.getItem('email') || '');
	const [loading, setLoading] = useState<boolean>(false);
	const [showToast, setShowToast] = useState(false);
	const [toastSeverity, setToastSeverity] = useState<'success' | 'error' | undefined>(undefined);
	const [toastMessage, setToastMessage] = useState<string>('');
	const [isBtnLoading, setBtnLoading] = useState<boolean>(false);
	const [isEmailRequired, setIsEmailRequired] = useState<boolean>(false);
	const[emailNotCorrect,setEmailNotCorrect] = useState<boolean>(false);

	const handleEmailChange = (event: { target: { value: string } }) => {
		const newEmail = event.target.value;
		setEmail(newEmail);
	
		if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newEmail)) {
			setIsEmailRequired(true);
		} else {
			setIsEmailRequired(false);
		}
	};
	

	// const validateEmail = (email:any) => {

	// 	// custom email regex
	// 	const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	  
	// 	if (!email || email.length === 0) {
	// 	  return 'Email is required'; 
	// 	}
	  
	// 	if (!regex.test(email)) {
	// 	  return 'Invalid email format';
	// 	}
	  
	// 	// other custom validations...
		
	// 	return null; 
	//   }

	const handleBack = async () => {
		// setForgotPassword(false);
		// setLoginIsVisible(true);
	};
	
	const navigate = useNavigate()

	const handleSend = async (): Promise<void> => {
		setShowToast(false);
		if (!isValidEmail(email)) {
			setShowToast(true);
			setToastSeverity('error');
			setToastMessage('Please enter a valid email address.');
			return;
		}

		try {
			setBtnLoading(true);
			setShowToast(false);
			const payload = { email };
			localStorage.setItem('email', email);
			const response = await AuthServices.forgotPassword(payload);
			if (response?.error) {
				setBtnLoading(false);
				setShowToast(true);
				setToastSeverity('error');
				setToastMessage(response?.message);
				setEmailNotCorrect(true);
				setIsEmailRequired(true);
			} else {
				setBtnLoading(false);
				setShowToast(true);
				setToastSeverity('success');
				setToastMessage(response?.message);
				setTimeout(() =>{ 
					navigate('/verificationCode')
			}, 5000);
			}
		} catch (error) {
			setBtnLoading(false);
			setShowToast(true);
			setToastSeverity('error');
			setToastMessage('An unexpected error occurred.');
		} finally {
			setBtnLoading(false);
			setLoading(false);
		}
	};

	return (
		<Box
			sx={{
				maxWidth: 'fit-content',
				marginLeft: 'auto',
				marginRight: 'auto',
				paddingTop: '10rem',
			}}>
			{showToast && (
				<ToastComponent
					severity={toastSeverity}
					label={toastSeverity === 'error' ? 'Error' : 'Success'}
					message={toastMessage}
					show={showToast}
				/>
			)}
			<Card>
				<CardBody>
					<Box sx={{ maxWidth: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}>
						<img src={Logo} alt='' />
					</Box>
					<Box sx={{ marginTop: '3rem' }}>
						<Typography style={LoginHeader}>Forgot Password</Typography>
						<div className='row g-3 my-2'>
							<Box sx={{ height: 10 }}>
								<Typography style={sliderHearedText}>Email address</Typography>
							</Box>
							<div className='col-12'>
								<FormGroup id='email' label=''>
									<Input
										value={email}
										type='email'
										name='email'
										placeholder=''
										style={{
											...sliderBodyText,
											borderColor:isEmailRequired?'red':'transparent'
										}}
										onChange={handleEmailChange}
									/>
									<>
										{isEmailRequired ? (
											<Typography variant='caption' style={validationError}>
												Please Enter a valid Email
											</Typography>
										) : null}
									
									</>
								</FormGroup>
							</div>
						</div>
						
						{/* <Box> */}
							
						{/* </Box> */}
					</Box>
					<Box>
					{/* <CardFooter style={{gap:'3rem'}}>	 */}
						{/* <LoginButton onClick={handleBack}>Back</LoginButton> */}
						<SubmitButton onClick={handleSend}>
								{' '}
								{isBtnLoading ? (
									<>
										<span
											className='spinner-grow spinner-grow-sm'
											role='status'
											aria-hidden='true'></span>
										Sending...
									</>
								) : (
									'Send'
								)}
							</SubmitButton>
						{/* </CardFooter> */}
						</Box>
				</CardBody>
			</Card>
		</Box>
	);
}

export default ForgotPassword;
