import React, { SVGProps } from 'react';

const SvgDashboard = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg   {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.5 19.9V4.1C10.5 2.6 9.86 2 8.27 2H4.23C2.64 2 2 2.6 2 4.1V19.9C2 21.4 2.64 22 4.23 22H8.27C9.86 22 10.5 21.4 10.5 19.9Z" stroke="#8E98A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 8.52V3.98C22 2.57 21.36 2 19.77 2H15.73C14.14 2 13.5 2.57 13.5 3.98V8.51C13.5 9.93 14.14 10.49 15.73 10.49H19.77C21.36 10.5 22 9.93 22 8.52Z" stroke="#8E98A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 19.77V15.73C22 14.14 21.36 13.5 19.77 13.5H15.73C14.14 13.5 13.5 14.14 13.5 15.73V19.77C13.5 21.36 14.14 22 15.73 22H19.77C21.36 22 22 21.36 22 19.77Z" stroke="#8E98A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

	);
};

export default SvgDashboard;
