


import React, { SVGProps } from 'react';
const SvgCustomApple = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" >
        <g clip-path="url(#clip0_2758_28851)">
            <path opacity="0.3" d="M14 7H8V21H19V12H14V7Z" fill="#8E98A7" />
            <path d="M16 1H4C2.9 1 2 1.9 2 3V17H4V3H16V1ZM15 5H8C6.9 5 6.01 5.9 6.01 7L6 21C6 22.1 6.89 23 7.99 23H19C20.1 23 21 22.1 21 21V11L15 5ZM19 21H8V7H14V12H19V21Z" fill="#8E98A7" />
        </g>
        <defs>
            <clipPath id="clip0_2758_28851">
                <rect width="30" height="30" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default SvgCustomApple;
