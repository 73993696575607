import React, { SVGProps } from 'react';

const SvgInfoOutline = (props: SVGProps<SVGSVGElement>) => {
	return (


		<svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.5C14.4183 18.5 18 14.9183 18 10.5C18 6.08172 14.4183 2.5 10 2.5C5.58172 2.5 2 6.08172 2 10.5C2 14.9183 5.58172 18.5 10 18.5ZM10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5Z" fill="#233145" />
			<path fill-rule="evenodd" clip-rule="evenodd" d="M10 7.5C10.5523 7.5 11 7.05228 11 6.5C11 5.94772 10.5523 5.5 10 5.5C9.4477 5.5 9 5.94772 9 6.5C9 7.05228 9.4477 7.5 10 7.5ZM10 8.5C9.4477 8.5 9 8.9477 9 9.5V14.5C9 15.0523 9.4477 15.5 10 15.5C10.5523 15.5 11 15.0523 11 14.5V9.5C11 8.9477 10.5523 8.5 10 8.5Z" fill="#233145" />
		</svg>

	);
};

export default SvgInfoOutline;
