
export const colour = {
	tenderHeader:'#052853',
    tenderSubHeader:'#8E98A7',
    tenderNewBtn:'#233145',
    tenderDays:"#7C7C7C",
    tableheader:'#323232',
    projectName:'#5887F5',
    tableCommonColour:'#616161',
    statusButn:'#46BCAA',
    white:"#ffffff",
    pannelHeader:"#737374",
    selectTab:'#F8F9FA',
    black:"#000",
    darkBtn:'#202029',
    pdfText:'#46BCAA',
    close:'#F35421',
    active:'#46BCAA'
};