


import React, { SVGProps } from 'react';
const SvgCustomApple = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
        <path d="M0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0H2C0.9 0 0 0.9 0 2ZM2 2H16V16H2V2ZM4 7H6V14H4V7ZM8 4H10V14H8V4ZM12 10H14V14H12V10Z" fill="url(#paint0_linear_2758_28640)" />
        <defs>
            <linearGradient id="paint0_linear_2758_28640" x1="9" y1="0" x2="9" y2="18" gradientUnits="userSpaceOnUse">
                <stop stop-color="#E935DD" />
                <stop offset="1" stop-color="#5887F5" />
            </linearGradient>
        </defs>
    </svg>

);

export default SvgCustomApple;
