import React from 'react';
import { RouteProps } from 'react-router-dom';
import DefaultAside from '../pages/_layout/_asides/DefaultAside';

const asides: RouteProps[] = [
  { path: '/' }, 
  { path: '/forgotPassword' }, 
  { path: '/verificationCode' }, 
  { path: '/resetPassword' }, 
  { path: '*', element: <DefaultAside /> },
];

export default asides;
