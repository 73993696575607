import React, { SVGProps } from 'react';

const SvgPlayDisabled = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width="31" height="13" viewBox="0 0 31 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M9.72656 0.625V12H7.57031L2.73438 4.10938V12H0.585938V0.625H2.73438L7.58594 8.52344V0.625H9.72656ZM15.5547 12.1562C14.9141 12.1562 14.3385 12.0521 13.8281 11.8438C13.3177 11.6354 12.8828 11.3464 12.5234 10.9766C12.1693 10.6016 11.8958 10.1667 11.7031 9.67188C11.5156 9.17188 11.4219 8.63542 11.4219 8.0625V7.75C11.4219 7.09896 11.5156 6.50781 11.7031 5.97656C11.8906 5.4401 12.1562 4.97917 12.5 4.59375C12.8438 4.20833 13.2552 3.91146 13.7344 3.70312C14.2135 3.49479 14.7422 3.39062 15.3203 3.39062C15.9193 3.39062 16.4479 3.49219 16.9062 3.69531C17.3646 3.89323 17.7474 4.17448 18.0547 4.53906C18.362 4.90365 18.5938 5.34115 18.75 5.85156C18.9062 6.35677 18.9844 6.91667 18.9844 7.53125V8.39844H12.3594V6.97656H16.9531V6.82031C16.9427 6.49219 16.8802 6.19271 16.7656 5.92188C16.651 5.64583 16.474 5.42708 16.2344 5.26562C15.9948 5.09896 15.6849 5.01562 15.3047 5.01562C14.9974 5.01562 14.7292 5.08333 14.5 5.21875C14.276 5.34896 14.0885 5.53646 13.9375 5.78125C13.7917 6.02083 13.6823 6.30729 13.6094 6.64062C13.5365 6.97396 13.5 7.34375 13.5 7.75V8.0625C13.5 8.41667 13.5469 8.74479 13.6406 9.04688C13.7396 9.34896 13.8828 9.61198 14.0703 9.83594C14.263 10.0547 14.4922 10.2266 14.7578 10.3516C15.0286 10.4714 15.3359 10.5312 15.6797 10.5312C16.112 10.5312 16.5026 10.4479 16.8516 10.2812C17.2057 10.1094 17.513 9.85677 17.7734 9.52344L18.8125 10.6016C18.6354 10.862 18.3958 11.112 18.0938 11.3516C17.7969 11.5911 17.4375 11.7865 17.0156 11.9375C16.5938 12.0833 16.1068 12.1562 15.5547 12.1562ZM22.7188 9.96875L24.5703 3.54688H25.8828L25.4922 5.76562L23.625 12H22.4922L22.7188 9.96875ZM21.6953 3.54688L23.0859 9.98438L23.2109 12H21.9062L19.6875 3.54688H21.6953ZM27.6016 9.89062L28.9609 3.54688H30.9609L28.7578 12H27.4531L27.6016 9.89062ZM26.0859 3.54688L27.9219 9.90625L28.1641 12H27.0312L25.1484 5.76562L24.7656 3.54688H26.0859Z" fill="#8E98A7" />
		</svg>

	);
};

export default SvgPlayDisabled;
