import React, { useState } from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import Search from '../../../components/Search';
import CommonHeaderRight from './CommonHeaderRight';
import { Box } from '@mui/material';
import { IconField } from 'primereact/iconfield';
import { InputText } from 'primereact/inputtext';
import { InputIcon } from 'primereact/inputicon';

function TopHeaderComponent() {
	const [searchInput, setSearchInput] = useState('');
	const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const searchText = event.target.value;
		setSearchInput(searchText);
	};
	return (
		<Header>
			<HeaderLeft>
				{/* <Search/> */}
				<Box maxWidth={'700px'}>
					{/* <IconField
								style={{
									width: '700px',
									borderRadius: '10px',
									background: '#fff',
									padding: '5px',
									marginRight: '20px',
								}}>
								<InputText
									value={searchInput}
									onChange={handleSearchInputChange}
									placeholder='Search Parent Company Name'
									style={{
										width: 'calc(100% - 30px)',
										borderRadius: '10px',
										background: '#fff',
										border: 'none',
										paddingLeft: '30px',
										boxShadow: 'none',
										color: 'var(--text-light-gray, #233145)',
										fontFamily: 'Roboto',
										fontSize: '14px',
										fontStyle: 'normal',
										fontWeight: 600,
										lineHeight: 'normal',
									}}
								/>
								<InputIcon
									className='pi pi-search'
									style={{
										position: 'absolute',
										left: '10px',
										top: '60%',
										transform: 'translateY(-50%)',
									}}
								/>
							</IconField> */}
					<IconField
						style={{
							width: '26rem',
							borderRadius: '10px',
							padding: '5px',
						}}>
						<InputText
							value={searchInput}
							onChange={handleSearchInputChange}
							placeholder='Search'
							style={{
								width: 'calc(100% - 30px)',
								border: 'none',
								paddingLeft: '30px',
								boxShadow: 'none',
							}}
						/>
						<InputIcon
							className='pi pi-search'
							style={{
								position: 'absolute',
								left: '10px',
								top: '60%',
								transform: 'translateY(-50%)',
							}}
						/>
					</IconField>
				</Box>
			</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
}

export default TopHeaderComponent;
