import React, { SVGProps } from 'react';

const SvgCustomNodeMasterData = (props: SVGProps<SVGSVGElement>) => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clip-path="url(#clip0_6014_12001)">
<path d="M13 2.0498V5.0798C16.39 5.5698 19 8.4698 19 11.9998C19 12.8998 18.82 13.7498 18.52 14.5398L21.12 16.0698C21.68 14.8298 22 13.4498 22 11.9998C22 6.8198 18.05 2.5498 13 2.0498ZM12 18.9998C8.13 18.9998 5 15.8698 5 11.9998C5 8.4698 7.61 5.5698 11 5.0798V2.0498C5.94 2.5498 2 6.8098 2 11.9998C2 17.5198 6.47 21.9998 11.99 21.9998C15.3 21.9998 18.23 20.3898 20.05 17.9098L17.45 16.3798C16.7976 17.1989 15.9686 17.8599 15.0249 18.3136C14.0812 18.7673 13.0471 19.0019 12 18.9998Z" fill="#8E98A7"/>
</g>
<defs>
<clipPath id="clip0_6014_12001">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>



);

export default SvgCustomNodeMasterData;
