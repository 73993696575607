import React, { SVGProps } from 'react';

const SvgNotifications = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.0225 2.91003C8.71253 2.91003 6.02253 5.60003 6.02253 8.91003V11.8C6.02253 12.41 5.76253 13.34 5.45253 13.86L4.30253 15.77C3.59253 16.95 4.08253 18.26 5.38253 18.7C9.69253 20.14 14.3425 20.14 18.6525 18.7C19.8625 18.3 20.3925 16.87 19.7325 15.77L18.5825 13.86C18.2825 13.34 18.0225 12.41 18.0225 11.8V8.91003C18.0225 5.61003 15.3225 2.91003 12.0225 2.91003Z" stroke="#8E98A7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M13.8719 3.19994C13.5619 3.10994 13.2419 3.03994 12.9119 2.99994C11.9519 2.87994 11.0319 2.94994 10.1719 3.19994C10.4619 2.45994 11.1819 1.93994 12.0219 1.93994C12.8619 1.93994 13.5819 2.45994 13.8719 3.19994Z" stroke="#8E98A7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.0234 19.0601C15.0234 20.7101 13.6734 22.0601 12.0234 22.0601C11.2034 22.0601 10.4434 21.7201 9.90344 21.1801C9.36344 20.6401 9.02344 19.8801 9.02344 19.0601" stroke="#8E98A7" stroke-width="1.5" stroke-miterlimit="10"/>
</svg>

	);
};

export default SvgNotifications;
