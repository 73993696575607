import React, { SVGProps } from 'react';

const SvgPhotoAlbum = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.5 1H14.6538L24.5 9.16V24C24.5 24.5523 24.0523 25 23.5 25H1.5C0.947715 25 0.5 24.5523 0.5 24V2C0.5 1.44772 0.947715 1 1.5 1Z" fill="#DFE5F1" />
			<path d="M14.6538 1V8.16C14.6538 8.71228 15.1016 9.16 15.6538 9.16H22.0385H24.5M14.6538 1H1.5C0.947715 1 0.5 1.44772 0.5 2V24C0.5 24.5523 0.947715 25 1.5 25H23.5C24.0523 25 24.5 24.5523 24.5 24V9.16M14.6538 1C18.499 4.18668 20.6548 5.97332 24.5 9.16" stroke="#8E98A7" />
			<path d="M4.5 14.4795H20.5" stroke="#8E98A7" />
			<path d="M4.5 19.2793H20.5" stroke="#8E98A7" />
			<path d="M15.0336 8.68H23.0862C23.5576 8.68 23.7671 8.0875 23.4005 7.79117L15.7506 1.60678C15.4353 1.35189 14.9636 1.55854 14.9373 1.96313L14.5347 8.14752C14.5159 8.43583 14.7447 8.68 15.0336 8.68Z" fill="#8E98A7" />
		</svg>

	);
};

export default SvgPhotoAlbum;
