import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ThreeDotsProps {
	width?: number;
	height?: number;
}
const ThreeDots: FC<ThreeDotsProps> = ({ width, height }) => {
	return (
        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="18" viewBox="0 0 5 18" fill="none">
        <path d="M4.5 15.6667C4.5 16.1971 4.28929 16.7058 3.91421 17.0809C3.53914 17.456 3.03043 17.6667 2.5 17.6667C1.96957 17.6667 1.46086 17.456 1.08579 17.0809C0.710713 16.7058 0.5 16.1971 0.5 15.6667C0.5 15.1363 0.710713 14.6276 1.08579 14.2525C1.46086 13.8774 1.96957 13.6667 2.5 13.6667C3.03043 13.6667 3.53914 13.8774 3.91421 14.2525C4.28929 14.6276 4.5 15.1363 4.5 15.6667ZM4.5 9.00004C4.5 9.53047 4.28929 10.0392 3.91421 10.4143C3.53914 10.7893 3.03043 11 2.5 11C1.96957 11 1.46086 10.7893 1.08579 10.4143C0.710713 10.0392 0.5 9.53047 0.5 9.00004C0.5 8.46961 0.710713 7.9609 1.08579 7.58583C1.46086 7.21075 1.96957 7.00004 2.5 7.00004C3.03043 7.00004 3.53914 7.21075 3.91421 7.58583C4.28929 7.9609 4.5 8.46961 4.5 9.00004ZM4.5 2.33337C4.5 2.86381 4.28929 3.37252 3.91421 3.74759C3.53914 4.12266 3.03043 4.33337 2.5 4.33337C1.96957 4.33337 1.46086 4.12266 1.08579 3.74759C0.710713 3.37252 0.5 2.86381 0.5 2.33337C0.5 1.80294 0.710713 1.29423 1.08579 0.919161C1.46086 0.544088 1.96957 0.333374 2.5 0.333374C3.03043 0.333374 3.53914 0.544088 3.91421 0.919161C4.28929 1.29423 4.5 1.80294 4.5 2.33337Z" fill="#8E98A7"/>
        </svg>
	);
};
ThreeDots.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
ThreeDots.defaultProps = {
	width: 2155,
	height: 854,
};

export default ThreeDots;