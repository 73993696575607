import axios from 'axios';

const apiUrl =  process.env.REACT_APP_API_URL_DEV;
const apiUrl2 = process.env.REACT_APP_API_URL_DEV_2;
const authToken = localStorage.getItem('access_token')
const userManagment =process.env.REACT_APP_USERMANGMENT;
const multiTenant =process.env.REACT_APP_MULTI_TENANT;


export const http = axios.create({
	baseURL: '',
	headers: {
		'Content-type': 'application/json',
		Authorization: `Bearer ${authToken}`,
	},
});

export const userManagmentServer = axios.create({
	baseURL: userManagment,
	headers: {
		'Content-type': 'application/json',
		Authorization: `Bearer ${authToken}`,
	},
});

export const userManagmentFomData = axios.create({
	baseURL: apiUrl,
	headers: {
		'Content-type': 'multipart/form-data',
		Authorization: `Bearer ${authToken}`,
	},
});

export const multiTenantServer = axios.create({
	baseURL: multiTenant,
	headers: {
		'Content-type': 'application/json',
		Authorization: `Bearer ${authToken}`,
	},
});

export const multiTenantServerFomData = axios.create({
	baseURL: multiTenant,
	headers: {
		'Content-type': 'multipart/form-data',
		Authorization: `Bearer ${authToken}`,
	},
});




export const devServer = axios.create({
	baseURL: apiUrl,
	headers: {
		'Content-type': 'application/json',
		Authorization: `Bearer ${authToken}`,
	},
});

export const devFomData = axios.create({
	baseURL: apiUrl,
	headers: {
		'Content-type': 'multipart/form-data',
		Authorization: `Bearer ${authToken}`,
	},
});

export const devProjectServer = axios.create({
	baseURL: apiUrl2,
	headers: {
		'Content-type': 'application/json',
		Authorization: `Bearer ${authToken}`,
	},
})
