// reducers.ts

import {
  FETCH_POLICY_FOR_EMAIL_REQUEST,
  FETCH_POLICY_FOR_EMAIL_SUCCESS,
  FETCH_POLICY_FOR_EMAIL_FAILURE,
  } from '../Action/abacPolicyAction';
  
  const initialState = {
    abacPolicyList: [],
    isLoading: false,
    error: '',
  };
  
  const abacReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case FETCH_POLICY_FOR_EMAIL_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case FETCH_POLICY_FOR_EMAIL_SUCCESS:
        return {
          ...state,
          abacPolicyList: action.payload,
          isLoading: false,
        };
      case FETCH_POLICY_FOR_EMAIL_FAILURE:
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default abacReducer;
  